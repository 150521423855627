import React, { useEffect, useState, useRef } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Twitter from "../images/twitter.png";
import Insta from "../images/insta.png";
import Facebook from "../images/fbbv.png";
import GlobalService from "../services/globalService";
import api from "../apis";
import { NavLink, useNavigate } from "react-router-dom";
import localService from "../services/localService";
import { toast } from 'react-toastify';

// Sidebar include
import SideBar from "../components/sidebar/SideBar";

function Social({ prevStep, nextStep }) {

  const userData = GlobalService.localStorageData;
  const navigate = useNavigate();

  // const localStorageUserData = localStorage.getItem("localStorage_userData");
  // const userLocalItem = JSON.parse(localStorageUserData);

  const twitterh = (userData != null && userData.twitter_handle != null) ? userData.twitter_handle : '';
  const instagramh = (userData != null && userData.instagram_handle != null && userData.instagram_handle != undefined) ? userData.instagram_handle.toLowerCase() : '';
  const facebookh = (userData != null && userData.facebook_name != null && userData.facebook_name != undefined) ? userData.facebook_name.toLowerCase() : '';



  const [twitter, setTwitter] = useState(twitterh);
  const [instagram, setInstagram] = useState(instagramh);
  const [facebook, setFacebook] = useState(facebookh);
  const [updateAlloweData, setUpdateAlloweData] = useState({})

  const [twtErr, setTwtErr] = useState(false);
  const [insErr, setInsErr] = useState(false);
  const [fbErr, setFbErr] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    getSocialInfo();
  }, []);


  const getSocialInfo = () => {

    const response = api.userCheckSocial.get().then((res) => {
      console.log('res', res);

      if (res.data.status !== null) {
        setUpdateAlloweData(res.data)
        if (res.data.code != undefined && res.data.code === 'update_allowed') {
          setEditable(true);
        }

      }
    }).catch((err) => {
      toast.error(err.message);
    });
  }



  const doConfirm = (e) => {
    e.preventDefault();
    let fb = /^[a-z\d.]{5,}$/i;
    let ins = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/gim;
    let twit = /^([a-z0-9_]{2,15})$/i;
    let error = false;

    if (twitter.trim() != "" && !twit.test(twitter)) {
      toast.error("Invalid twitter handle!");
      setTwtErr(true);
      error = true;
      // return false;
    } else if (instagram.trim() != "" && !ins.test(instagram)) {
      toast.error("Invalid instagram handle!");
      setInsErr(true);
      error = true;
      // return false;
    } else if (facebook.trim() != "" && facebook.length < 5) {
      toast.error("Facebook handle should be minimum 5 characters in length!");
      setFbErr(true);
      error = true;
      // return false;
    } else if (facebook.trim() != "" && !fb.test(facebook)) {
      toast.error("Invalid facebook handle!");
      setFbErr(true);
      error = true;
      // return false;
    }
    if (error === false) {
      doSubmit();

    } else {
      return false;
    }
  };

  const doSubmit = () => {
    setShowLoader(true);
    let payload = {

      twitter_handle: twitter.trim(),
      instagram_handle: instagram.trim(),
      facebook_name: facebook.trim(),
    };
    console.log("payload", payload);
    const response = api.updateSocial.updateSocial(payload).then((response) => {
      console.log("response social", response);

      if (response.data.status) {
        setShowLoader(false);
        toast.success(response.data.message);
        GlobalService.userData = response.data.data.user_data;
        localService.setUserData(response.data.data.user_data);
        navigate('/dashboard')

      } else {
        toast.error(response.data.message);
        setShowLoader(false);
      }
    }).catch((err) => {
      console.log(err)
      setShowLoader(false);
      toast.error(err.message);
    });

  }

  return (
    <>
      <div className="container-fluid pl-0 free-consultant-page-container">
        <Row>

          <Col lg={2} className="px-0">
              <div className="sticky-head border-0 sideBarStick">
                <SideBar/>
              </div>
          </Col>

          <Col lg={10} className="pl-4">
            <Container>
              {/* <Row className="my-5 pb-3 sticky-head">      ***with this it is become a sticky header if needed will change this */}
              <Row className="my-4 pb-3 contentBoxing" >
                <Col md={12} className="d-none d-md-block">
                  <h2>Social Accounts</h2>
                  {updateAlloweData.status != undefined && !updateAlloweData.status ?
                    <div>
                      <h6 style={{ color: 'red', textAlign: 'center' }}>{updateAlloweData.message}</h6>
                    </div> : null}
                </Col>
              </Row>
              {/* the below open and close Row is just added for the space same as the other screens */}
              <Row className="my-4 pb-3" ></Row>
              <div
                disabled={updateAlloweData.status != undefined && !updateAlloweData.status ? disabled : ''}
              >
                <Row>
                  <Col md={8} className="m-auto">
                    <Form>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          <img src={Twitter} alt="" className="social-icon" />
                        </span>
                        <input
                          type="text"
                          maxLength={40}
                          value={twitter}
                          onChange={(e) => setTwitter(e.target.value)}
                          className="form-control"
                          placeholder="@username"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          <img src={Insta} alt="" className="social-icon" />
                        </span>
                        <input
                          type="text"
                          maxLength={40}
                          value={instagram}
                          onChange={(e) => setInstagram(e.target.value)}
                          className="form-control"
                          placeholder="@username"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          <img src={Facebook} alt="" className="social-icon" />
                        </span>
                        <input
                          type="text"
                          maxLength={40}
                          value={facebook}
                          onChange={(e) => setFacebook(e.target.value)}
                          className="form-control"
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>

                      <button
                        type="submit"
                        class="form-control my-4 btn btn-primary py-2 btn-theme"
                        onClick={doConfirm}
                      >Update
                        {showLoader ?
                          <FontAwesomeIcon icon={faSpinner} className="spinner" />
                          : null}
                      </button>
                    </Form>
                  </Col>
                </Row>
              </div>
              {updateAlloweData.status ?
                <div>
                  <h6 style={{ color: 'clack', textAlign: 'center' }}>{updateAlloweData.message}</h6>
                </div> : null}
                {/* the below open and close Row is just added for the space same as the other screens */}
              <Row className="my-4 pb-3" ></Row>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Social;
