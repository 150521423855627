import React, { useEffect, useState, useRef } from "react";
import { Container, Form, Button, Col, Row, Image } from "react-bootstrap";
import {
  faArrowRight,
  faGlobe,
  faL,
  faSearch,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import GlobalService from "../../services/globalService";
import api from "../../apis";
import background from "../../images/ripplenew.gif";
import { NavLink, useNavigate } from "react-router-dom";
import ripple from "../../images/ripplenew.gif"
// import { Col, Container, Row, Form } from "react-bootstrap";
import localService from "../../services/localService";
import loginLogo from '../../images/logo.png'
import { toast } from 'react-toastify';

function StepFour({ prevStep, nextStep }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const nextCall = (e) => {
    e.preventDefault();
    const email_rejex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const password_rejex =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const inValid = /\s/;
    if (email != "" && !email_rejex.test(email)) {
      toast.error("Please enter a valid email address");
      return false;
    } else if (email_rejex.test(email)) {
      console.log("valid email");
    }
    if (password != "") {
      if (password.length > 7) {
        if (password_rejex.test(password) && !inValid.test(password)) {
          if (!disabled) {
            let guest_user_id = '';
            var guest_user_data = JSON.parse(localStorage.getItem('guest_user_data'));
            if(guest_user_data != null) {
              guest_user_id = guest_user_data.id;
            }
  // var createdDate = new Date(guest_user_data.created_at);
            setShowLoader(true);
            let payload = {
              step: 4,
              type: "web",
              onb_id: GlobalService.register_id,
              email: email.trim(),
              password: password,
              guest_user_id: guest_user_id
            };
            const response = api.register
              .register(payload)
              .then(async (response) => {
                console.log("res from forthsetp", response);
                if (response.data.status) {
                  // GlobalService.authtoken = response.data.token;
                  localStorage.setItem("token", response.data.data.token);
                  // GlobalService.userData = response.data.data.user_data;
                  localService.setUserData(response.data.data.user_data);
                  setTitle(response.data.data.modal_title);
                  setMessage(response.data.data.modal_msg);
                  setShowLoader(false);

                  // if(localStorage.getItem('redirected_from') == 'scan-result') {
                  //   navigate("/scanning-result");
                  // }
                  setShowModal(true);
                  callDashboardApi();

                  // callDashboardApi(response.data.data.token, async () => {

                  //   setTimeout(() => {
                  //     navigate("/dashboard");
                  //     // nextStep()
                  //   }, 1500);
                  // });
                } else {
                  setShowLoader(false);
                  toast.error(response.data.message);
                }
              })
              .catch((err) => {
                setShowLoader(false);
                console.log(err.data.message);
                console.log(err);
              });
          }
        } else {
          toast.error(
            "Invalid Password Format! The password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character, but cannot contain whitespace!"
          );
        }
      } else {
        toast.error("The password must be at least 8 character!");
      }
    }
  };

  const callDashboardApi = async (token, cb) => {
    const response = api.dashboard.get().then((response) => {
      if (response.data.status) {
        console.log("response callDashboardApi", response);
        GlobalService.userDashboardAll = response.data.data.user;
        console.log('GlobalService.userDashboardAll==============', GlobalService.userDashboardAll)
        setTimeout(() => {
          // if(localStorage.getItem('redirected_from') == 'scan-result') {
          // } else {
          //   navigate("/dashboard");
          // }

          navigate("/dashboard");
        }, 1500);
        // cb(response);
      }
    })
      .catch((err) => {
        // cb(err);
        console.log(err)
        toast.error(err.data.message);
      });
  };

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const validate = () => {
    return email.length && password.length;
  };

  return (
    <>
      {!showModal && (
        <div className="log-reg-header position-relative px-3">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="topBarLogo">
                  <Image src={loginLogo} alt="Login Logo" width={200}/>
                </div>
              </Col>
              <Col lg={12}>
                <div className="text-center welcomeHeads">
                  <h2>Complete account setup</h2>
                  <p>Add your email and set a password to complete your account.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {showModal ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="w-100 pb-2 text-center">
            <FontAwesomeIcon icon={faGlobe} style={{ color: '#fff', fontSize: '1.4rem', position: 'absolute', left: 0, right: 0, margin: 'auto', top: '42.5%', }} />
            <img src={ripple} alt="" />
            <h2>{title}</h2>
            <p>{message}</p>
          </div>
        </div>
      ) : null}
      {!showModal && (
        <div className="log-reg-footer  position-relative">
          <Form>
            <FloatingLabel
              controlId="floatingInput"
              label="Email address"
              className="mb-3"
            >
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => {
                  // setFname(e.target.value)
                  handleEmail(e);
                }}
              />
            </FloatingLabel>
            <FloatingLabel controlId="floatingPassword" label="Password">
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  // setFname(e.target.value)
                  handlePassword(e);
                }}
              />
            </FloatingLabel>

            <Container style={{
              position: "absolute",
              bottom: "20px",
              left: "0px",
              margin: "auto"
            }}>
              <Row>
                <Col md={3} xs={4}>
                  <Button
                    variant="primary"
                    onClick={prevStep}
                    className="mt-4 w-100 subButton"
                  >
                    Previous
                  </Button>
                </Col>
                <Col md={9} xs={8}>
                  <Button
                    className="form-control mt-4 subButton"
                    onClick={nextCall}
                    disabled={!validate()}
                    // disabled={disabled}
                    type="submit"
                  >
                    Continue <FontAwesomeIcon icon={faArrowRight} />
                    {showLoader ? (
                      <FontAwesomeIcon icon={faSpinner} className="spinner" />
                    ) : null}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      )}
    </>
  );
}

export default StepFour;
