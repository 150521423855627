import React, { useEffect, useState } from "react";

// Bootstrap Module
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";

// Css path
import "../style/Header.scss"

// Images
import main_logo from "../../../images/logo_main.png"; 

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("localStorage_userData") !== null){
      setIsLoggedIn(true);
    }
  }, []);


  return (
    <header>
      <Container>
        <Row>
          <Col lg={12}>
            <Navbar collapseOnSelect expand="lg" className="justify-content-between">
              <Navbar.Brand href={"/"}>
                <img style={{width: '200px'}} src={main_logo} alt={main_logo} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav className="d-flex align-items-center">
                  <Nav.Item>
                    {isLoggedIn ? <Nav.Link href={"/dashboard"}>Home</Nav.Link>  : <Nav.Link href="https://www.internetprivacy.com/">Home</Nav.Link>}
                  </Nav.Item>
                  <Nav.Item>
                    {isLoggedIn ? null  : <Nav.Link href="https://www.internetprivacy.com/about/">About</Nav.Link>}
                  </Nav.Item>
                  <Nav.Item>
                    {isLoggedIn ? null  : <Nav.Link href="https://www.internetprivacy.com/pricing/">Pricing</Nav.Link>}
                  </Nav.Item>
                  <Nav.Item>
                    {isLoggedIn ? null  : <Nav.Link href='https://www.internetprivacy.com/blog/'>Blog</Nav.Link>}
                  </Nav.Item>
                  {/* <Nav.Item>
                    {isLoggedIn ? null  : <Nav.Link href="https://www.internetprivacy.com/contact/">Contact</Nav.Link>}
                  </Nav.Item> */}
                  {/* <Nav.Item>
                    {isLoggedIn ? null  : <Nav.Link href={"/signup"}>Sign Up</Nav.Link>}
                  </Nav.Item> */}
                  {!isLoggedIn && 
                    <Nav.Item>
                      <Nav.Link href={"/login"}>Log In</Nav.Link>
                    </Nav.Item>
                  }
                  {/* <Nav.Item>
                    {isLoggedIn ? <Nav.Link href={"/results"}>Results</Nav.Link>  : <Nav.Link href={"/login"}>Results</Nav.Link>}
                  </Nav.Item>
                  <Nav.Item>
                    {isLoggedIn ? <Nav.Link href={"/privacy"}>Privacy</Nav.Link>  : <Nav.Link href={"/login"}>Privacy</Nav.Link>}
                  </Nav.Item> */}

                  {/* {!isLoggedIn && 
                    <Nav.Item>
                      <Nav.Link href={"/login"}>Log In</Nav.Link>
                    </Nav.Item>
                  } */}

                  {/* <Nav.Item>
                    {isLoggedIn ? null  : <Nav.Link  className="headerPhone" href="tel:(941) 307-7692">(941) 307-7692</Nav.Link>}
                  </Nav.Item> */}
                  <Nav.Item>
                    {isLoggedIn ? null  : <Nav.Link className="headerPhone" href={"/guest-registration"}>Get Protection Now</Nav.Link>}{/*Solid bg class - headerProtect*/}
                  </Nav.Item>
                  {/* <Nav.Item>
                  {isLoggedIn ? <Nav.Link href={"/privacy"}  variant="outline-light">Free Privacy Scan</Nav.Link> :<Nav.Link href={"/guest-registration"}  variant="outline-light">Free Privacy Scan</Nav.Link> }
                  </Nav.Item> */}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Header