import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Image } from "react-bootstrap";
import { NavLink, redirect } from "react-router-dom";
import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import "./StepForm.scss";
import "./Registration.scss";
import GlobalService from '../../services/globalService';
import api from '../../apis';
import localService from "../../services/localService";
import { useNavigate } from "react-router-dom";
import loginLogo from '../../images/logo.png';
import { toast } from 'react-toastify';
import Header from '../scanningflow/header/Header';



function Registration({ handleFormData, values }) {

  var guest_user_data_first_name = '';
  var guest_user_data_last_name = '';
  if(localStorage.getItem('guest_user_data') !== null) {
    let guest_user_data = JSON.parse(localStorage.getItem('guest_user_data'));
    guest_user_data_first_name = guest_user_data.first_name;
    guest_user_data_last_name = guest_user_data.last_name;
  }
  

  const [fname, setFname] = useState(guest_user_data_first_name);
  const [lname, setLname] = useState(guest_user_data_last_name);
  const [lnameErr, setLnameErr] = useState(false);
  const [fnameErr, setFnameErr] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false)
  const [blankFname, setBlankFname] = useState(true);
  const [blankLname, setBlankLname] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();


  const handleFirstName = (e) => {
    setFname(e.target.value);
  }
  const handleLastName = (e) => {
    setLname(e.target.value);
  }
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const submitFormData = (e) => {
    e.preventDefault();
    let nameRegex = /^[a-zA-Z ]+$/;
    let error = false;

    if (fname != '' && !nameRegex.test(fname)) {
      toast.error('First name cannot contain numeric values or special characters');
      error = true;
      return false;
    }
    else {
      if (nameRegex.test(fname)) {
        error = false;
      }
    }

    if (lname != '' && !nameRegex.test(lname)) {
      toast.error('Last name cannot contain numeric values or special characters');
      error = true;
      return false;
    }
    else {
      if (nameRegex.test(lname)) {
        error = false;
      }
    }

    if (error === false) {
        document.getElementById("guest-user-footer").classList.add("disableGuestUserForm");
        setShowLoader(true);
        setDisabled(true);
        let guest_user_id = '';
        var guest_user_data = JSON.parse(localStorage.getItem('guest_user_data'));
        if(guest_user_data != null) {
            guest_user_id = guest_user_data.id;
        }

      let payload = {
        step: 1,
        first_name: fname.trim(),
        last_name: lname.trim(),
        device_token : localStorage.getItem('deviceToken'),
        email: email.trim(),
        password: password.trim(),
        type: "web",
        guest_user_id: guest_user_id
      }
      
      const response = api.register.guestUserRegistration(payload).then((response) => {
        if (response.data.status) {
          localStorage.setItem("token", response.data.data.token);
          localService.setUserData(response.data.data.user_data);
          callDashboardApi();
        }
        else {
          console.log(response);
          toast.error(response.data.message);
          setShowLoader(false);
          setDisabled(false);
          document.getElementById("guest-user-footer").classList.remove("disableGuestUserForm");
        }
      });
    } else {
      return false;
    }
  };


  const callDashboardApi = async (token, cb) => { 
    const responses = api.dashboard.get().then((response) => {
      console.log(response);
      if (response.data.status) {
        GlobalService.userDashboardAll = response.data.data.user;
        localStorage.setItem('removal_request_applied_for_the_site_name_details',response.data.data.removal_request_applied_for_the_site_name_details);
        //setTimeout(() => {
          setShowLoader(false);
          setDisabled(false);
          document.getElementById("guest-user-footer").classList.remove("disableGuestUserForm");

          if(localStorage.getItem('scan_page_from') !== null && localStorage.getItem('scan_page_from') == 'main_exposed_info_btn') {
            localStorage.removeItem('scan_page_from');
            navigate("/privacy");
          } else {
            //navigate("/scanning-result");
            navigate("/privacy");
          }
          
        //}, 1500);
        // cb(response);
      } else {
        toast.error(response.data.message);
        setShowLoader(false);
        setDisabled(false);
        document.getElementById("guest-user-footer").classList.remove("disableGuestUserForm");
      }
    })
      .catch((err) => {
        // cb(err);
        console.log(err)
        toast.error(err.data.message);
      });
  };

  const validationForm = async (value, names) => {
    // console.log('value', value)
    // console.log('fname',fname);
    // console.log('lname',lname)
    let nameRegex = /^[a-zA-Z]+$/;
    if (names == 'fname') {
      setFname(value);
      if (names != '' && nameRegex.test(value)) {
        setFnameErr(false);
        setBlankFname(false)
      }
      else {
        if (names != '' && !nameRegex.test(value)) {
          if (value == '') {
            setFnameErr(false)
            setBlankFname(true)
          } else {
            setFnameErr(true)
            setBlankFname(false)
          }
        } else if (names != '' && value == '') {
          setFnameErr(false)
        } else {
          setFnameErr(false)
        }
      }
    }
    else {
      setLname(value);
      if (names != '' && nameRegex.test(value)) {
        setLnameErr(false);
        setBlankLname(false)
      } else {
        if (names != '' && !nameRegex.test(value)) {
          if (value == '') {
            setLnameErr(false)
            setBlankLname(true)

          } else {
            setLnameErr(true)
            setBlankLname(false)
          }
        } else if (names != '' && value == '') {

          setLnameErr(false)
        } else {
          setLnameErr(false)
        }
      }
    }

    if (fnameErr == false && lnameErr == false) {
      if (fname != '' && lname != '') {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    } else {
      setDisabled(true)
    }
    GlobalService.register_data.fname = fname.trim();
    GlobalService.register_data.lname = lname.trim();


  }
  const validate = () => {
    return fname.length && lname.length && email.length && password.length;
  };


  return (
    <>
      <Header/>
      <div className="log-reg-header position-relative px-3 guest-user-header">
      <Container>
          <Row>
            {/* <Col lg={12}>
              <div className="topBarLogo">
                <Image src={loginLogo} alt="Login Logo" width={200}/>
              </div>
            </Col> */}
            <Col lg={12}>
              <div className="text-center welcomeHeads">
                <h2>Sign up to get started</h2>
                {/* <p>Enter your email to perform the search on!</p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="log-reg-footer  position-relative guest-user-footer" id="guest-user-footer">
        <Form>
          <Form.Group >
            <Form.Floating className="mb-4">
              <Form.Control
                style={{ background: fnameErr ? "#F88D99" : "" }}
                name="firstName"
                maxLength={25}
                type="text"
                placeholder="First Name"
                value={fname}
                onChange={(e) => {
                  handleFirstName(e)
                }}
              />
              <label htmlFor="fname">First name</label>
            </Form.Floating>

          </Form.Group>

          <Form.Group>
            <Form.Floating className="mb-4">
              <Form.Control
                style={{ background: lnameErr ? "#F88D99" : "" }}
                name="lastName"
                maxLength={25}
                type="text"
                placeholder="Last Name"
                value={lname}
                onChange={(e) => {
                  handleLastName(e)
                }}
              />
              <label htmlFor="lname">Last name</label>
            </Form.Floating>
          </Form.Group>

          <Form.Group>
            <Form.Floating className="mb-4">
              <Form.Control
                //style={{ background: lnameErr ? "#F88D99" : "" }}
                name="email"
                maxLength={125}
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => {
                    handleEmail(e);
                }}
              />
              <label htmlFor="email">Email</label>
            </Form.Floating>
          </Form.Group>

          <Form.Group>
            <Form.Floating className="mb-4">
              <Form.Control
                style={{ background: lnameErr ? "#F88D99" : "" }}
                name="password"
                maxLength={20}
                type="password"
                placeholder=""
                value={password}
                onChange={(e) => {
                    handlePassword(e);
                }}
              />
              <label htmlFor="password">Password</label>
            </Form.Floating>
          </Form.Group>


          <Button
            className="form-control mt-4 subButton btn btn-primary btn-theme"
            type="button"
            onClick={submitFormData}
            disabled={!validate()}
          // disabled={fnameErr == false && lnameErr == false && blankFname == false && blankLname == false ? false : true}
          >
            Submit & Get Started <FontAwesomeIcon icon={faArrowRight} />
            {showLoader ?
              <FontAwesomeIcon icon={faSpinner} className="spinner" />
              : null}
          </Button>
          <p className="w-100 d-flex justify-content-center mt-4 mb-0">
            Already have an account?{" "}
            <NavLink to="/login">
              <span className="ms-2 lg-rg-toggle signBtn">Sign In</span>
            </NavLink>
          </p>
        </Form>
      </div>
    </>
  );
}

export default Registration;
