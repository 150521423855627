import React from 'react'


// Bootstrap Module
import { Container, Row, Col, Form, Button, Image, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {  faFacebookF, faTwitter, faLinkedinIn  } from '@fortawesome/free-brands-svg-icons';


// Css path
import "../style/Footer.scss";
import footer_logo from '../../../images/logo_main.png';
import mcafee from '../../../images/mcafee.png';
import accec from '../../../images/accec.png';

function Footer() {

  const today = new Date();
  const year = today.getFullYear();

  return (
    <footer>
      <div className="footerTop">
        <Container>
          <Row>
            <Col lg={5} sm={12} xs={12}>
              <div className='footerBrand'>
                <a hreaf="javascript:;">
                  <Image src={footer_logo} alt="Footer Brand" />
                </a>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faPhoneVolume} color='#fff' />
                    <a href='tel:9412293573'>(941) 229-3573</a>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faEnvelope} color='#fff' />
                    <a href='mailto:support@internetprivacy.com'>support@internetprivacy.com</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={2} sm={12} xs={12}>
              <div className='footerNav'>
                <h3>Company</h3>
                <ul>
                  <li><a href='https://www.internetprivacy.com/about/'>About Us</a></li>
                  <li><a href='https://www.internetprivacy.com/pricing/'>Pricing</a></li>
                  <li><a href='https://www.internetprivacy.com/contact/'>Contact Us</a></li>
                  <li><a href='https://www.internetprivacy.com/blog/'>Blog</a></li>
                </ul>
              </div>
            </Col>
            <Col lg={5} sm={12} xs={12}>
              <div className='footerRightContent'>
                <div className='footerSocial'>
                  <ul>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faFacebookF} color='#000' />
                      </a>
                    </li>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faTwitter} color='#000' />
                      </a>
                    </li>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faLinkedinIn} color='#000' />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='secureBlock'>
                  <ul>
                    <li>
                      <Image src={mcafee}  alt='Macafee'/>
                    </li>
                    <li>
                      <Image src={accec}  alt='Macafee'/>
                    </li>
                  </ul>
                </div>
                <div className='footerSecure'>
                  <ul>
                    <li><a href="/policy">Privacy Policy</a></li>
                    <li><a href="https://www.internetprivacy.com/terms-of-use/" target="_blank">Terms of Use</a></li>
                  </ul>
                </div>
                {/* <p className='copyFooter'>&copy; Copyright {year} InternetPrivacy. All rights reserved.</p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footerBottom">
        <Container>
          <Row>
            <Col lg={12}>
                <p className="text-center text-white mt-3">&copy;Copyright {year} InternetPrivacy. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer