import dashboard from './dashboard'
import auth from './auth'
import states from './states'
import city from './city'
import register from './register'
import forgetPassword from './forgetPassword'
import userAccountUpdate from './userAccountUpdate'
import updateManageMonitoring from './updateManageMonitoring'
import userCheckMonitoring from './userCheckMonitoring'
import userCheckSocial from './userCheckSocial'
import updateSocial from './updateSocial'
import freeConsultation from './freeConsultation'
import sentiment from './sentiment'
import notification from './notification'
import updateNotification from './updateNotification'
import privacyApi from './privacyApi'
import stripeProductLists from './stripeProductLists'
import stripeuserExistance from './stripeuserExistance'
import stripeProductIdFetch from './stripeProductIdFetch'
import cancelSubscribe from './cancelSubscribe'
import scan from './scan'
import saveUserLocation from './saveUserLocation'
export default {
	dashboard,
	auth,
	states,
	city,
	register,
	forgetPassword,
	userAccountUpdate,
	updateManageMonitoring,
	userCheckMonitoring,
	userCheckSocial,
	updateSocial,
	freeConsultation,
	sentiment,
	notification,
	updateNotification,
	privacyApi,
	stripeProductLists,
	stripeuserExistance	,
	stripeProductIdFetch,
	cancelSubscribe,
	scan,
	saveUserLocation
}
