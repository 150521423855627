import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useForm, useState } from "react";
import { Container, Form, Button, Row, Col, Card, Image } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import api from "../../apis";
import loginLogo from '../../images/logo.png';
import Header from '../scanningflow/header/Header';
import { toast } from 'react-toastify';

function Forget() {
  const [email, setEmail] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const sendMail = (e) => {
    e.preventDefault();
    const email_rejex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email != "" && !email_rejex.test(email)) {
      toast.error("Please enter a valid email address");
      return false;
    } else if (email_rejex.test(email)) {
      console.log("valid email");
    }
    setShowLoader(true);
    let payload = {
      // device_id: GlobalService.device_id,
      email: email.trim(),
    };
    const response = api.forgetPassword
      .forgetPassword(payload)
      .then((response) => {
        console.log(response, "res--------");
        setShowLoader(false);
        if (response.data.status) {
          setSuccess(true);
          setMessage(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err.data.message);
        console.log(err);
      });
  };

  const validate = () => {
    return email.length;
  };
  return (
    <>
      {!success ? (
        <>
          <Header/>
          <div className="log-reg-header position-relative px-3">
            <Container>
              <Row>
                {/* <Col lg={12}>
                  <div className="topBarLogo">
                    <Image src={loginLogo} alt="Login Logo" width={200}/>
                  </div>
                </Col> */}
                <Col lg={12}>
                  <div className="text-center welcomeHeads">
                    <h2>Forgot your password?  </h2>
                    {/* <p>Please enter your registered email address.</p> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          
          <div className="log-reg-footer position-relative" style={{ height: 255}}>
            <Form.Floating className="mb-2">
              <Form.Control
                id="email"
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => {
                  handleEmail(e);
                }}
              />
              <label htmlFor="email">Email address</label>
            </Form.Floating>

            <Button
              variant="primary"
              className="form-control mt-4 subButton btn btn-primary btn-theme"
              disabled={!validate()}
              onClick={sendMail}
              style={{marginTop: 130 }}
            >
              Reset My Password <FontAwesomeIcon icon={faArrowRight} />
              {showLoader ? (
                <FontAwesomeIcon icon={faSpinner} className="spinner" />
              ) : null}
            </Button>

            <p className="w-100 d-flex justify-content-center mt-4 mb-0">
              <NavLink to="/login">
                <b className="ms-2 lg-rg-toggle">Return to login</b>
              </NavLink>
            </p>
          </div>
        </>
      ) : (
        <div
          style={{
            background: "#d1e9ff",
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="w-100 pb-2 text-center">
            <h2>Success!</h2>
            <p>{message}</p>
          </div>

          <NavLink to="/login">
            <b className="text-end d-block mt-3" style={{ color: "#101828" }}>
              Back to Login
            </b>
          </NavLink>
        </div>
      )}
    </>
  );
}

export default Forget;
