import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { NavLink, Link } from 'react-router-dom'
function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      {/* <div style={{height: '120px'}}></div> */}
      <div className="footerBtmSec">
        <Container fluid>
          <Row className="justify-content-end">
            <Col lg={10} md={9} className="p-0">
              <div className="site-footer">
                <ul>
                  <li>
                    <NavLink to="/policy" className="my-nav">Privacy Policy</NavLink>
                  </li>
                  <li>
                    <a
                      href="https://www.internetprivacy.com/terms-of-use/" target="_blank" className="my-nav">Terms </a>
                  </li>
                </ul>
                <p className="text-center text-white mt-3">&copy;Copyright {year} InternetPrivacy. All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
