import React from 'react'
import { Container, Form, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function RegistrationScreen2() {
  
    return (
        <>
          <div className="log-reg-header">
            <Container className="position-relative" style={{ height: "100%" }}>
              <div
                className="position-absolute top-50"
                style={{ transform: `translateY(${-50}px` }}
              >
                <h2>Where should we monitor?</h2>
                <p>In which country should we monitor?</p>
              </div>
            </Container>
          </div>
          <div className="log-reg-footer">
            <Container
              className="pt-4 position-relative"
              style={{ height: "100%" }}
            >
              <Form.Floating className="mb-4">
                <Form.Control id="first_name" type="text" placeholder="First name" />
                <label htmlFor="email">First name</label>
              </Form.Floating>
              <Form.Floating>
                <Form.Control
                  id="last_name"
                  type="text"
                  placeholder="Password"
                />
                <label htmlFor="password">Last name</label>
              </Form.Floating>
             
    
              <div className="position-absolute bottom-0 w-100 pb-3">
                <Button variant="light" className="form-control" disabled>
                  Continue <FontAwesomeIcon icon={faArrowRight} />
                </Button>
                <p className="w-100 d-flex justify-content-center mt-2">
                  
                  Don't have an account? <NavLink to="/"><b className="ms-2 lg-rg-toggle">Sign In</b></NavLink>
                </p>
              </div>
            </Container>
          </div>
        </>
      );
  
}

export default RegistrationScreen2