
import React, { useEffect, useState, useContext } from "react";
import { Container, Form, Button, Row, Col, Card, Navbar } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import GlobalService from "../../services/globalService";
import Logo from "../../images/logo.png";
import "./style.scss";
import { Helmet } from "react-helmet";
import api from "../../apis";
import AppContext from "../../context/AppContext"
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from 'react-toastify';

var checkValue = ""
function Index() {
    const userData = GlobalService.localStorageData;
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(false);
    const [showScript, setShowScript] = useState(false);

    let { userId } = useParams();
    const userIds = userId;
    console.log('userIds',userIds)
    var userID = userIds ? userIds : ""
    var decodedValue = window.atob(userID);
    console.log('decodedValue',decodedValue);


    const appCtx = useContext(AppContext);
    // console.log(appCtx.API_HOST_URL)

    useEffect(() => {
        userExistance();
    }, []);



    const userExistance = () => {
        console.log('object')
        // if(decodedValue)
        let payload = {
            user_id: decodedValue
        }
        
        setShowLoader(true);
        fetch(process.env.REACT_APP_API_HOST_URL + "ipv/checkUserExistance", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                if (data.status == true) {
                    setShowLoader(false);
                    setShowScript(true)
                }
                else {
                    setShowLoader(false);
                    toast.error("The user is not matching on our records! Please login with valid user!");
                    navigate("/");
                }

            })
            .catch((error) => {
                setShowLoader(false);
                console.error('Error:', error);
            });

    }


    return (
        <>
            {showLoader ?
                <div className="loader">
                    <FontAwesomeIcon icon={faSpinner} className="spinner"
                    />
                    <span>Loading...</span>
                </div>
                :

                <>
                    <div className="log-reg-header position-relative px-3">
                        <Container>
                            <div
                                className="position-absolute top-50"
                                style={{ transform: `translateY(${-50}px` }}
                            >
                                <Navbar.Brand href="#">
                                    <NavLink to="/" style={{ marginTop: 40 }}>
                                        <img src={Logo} className="logo2" />
                                    </NavLink>
                                </Navbar.Brand>
                            </div>
                        </Container>
                    </div>



                    <Row className="my-4 pb-3">
                        <Col md={12} className="d-none d-md-block">
                            <h2 style={{ marginLeft: "50px" }}>Plans & Billing</h2>

                        </Col>
                    </Row>
                    {showScript ?
                        <Row>

                            <div >
                                <Helmet>
                                    <script src="https://js.stripe.com/v3/pricing-table.js"></script>
                                </Helmet>
                                <stripe-pricing-table pricing-table-id="prctbl_1MdYSiKfBR346qLzMKxQgOZt"
                                    publishable-key="pk_test_51MTSk0KfBR346qLzhk1jpPt14j7x5vCcGEBTZuDZEc89LmKctFj9rCfdEC8iQGZngkyDc9nopTx1ls0oiszNU4V900EXMD1QGe"
                                    client-reference-id={decodedValue !== null ? decodedValue : ""}>
                                </stripe-pricing-table>
                            </div>

                        </Row>
                        : null}
                    <Row className="my-4 pb-3"></Row>
                </>
            }
        </>
    );
}

export default Index;
