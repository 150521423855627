import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import api from '../apis'
import GlobalService from "../services/globalService";
import { NavLink, useNavigate } from "react-router-dom";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import localService from "../services/localService";
import { toast } from 'react-toastify';


// Sidebar include
import SideBar from "../components/sidebar/SideBar";

function AccountSettings() {
  // const userData = GlobalService.localStorageData;
  const userData = GlobalService.localStorageData;
  // console.log('userData.display_first_name !==:',userData.display_first_name !== undefined ? userData.display_first_name : '')
  const [fname, setFname] = useState(userData !== null ? userData.display_first_name : '');
  const [lname, setLname] = useState(userData !== null ? userData.display_last_name : '');
  const [email, setEmail] = useState(userData !== null ? userData.email : '');
  const [curPassword, setCurPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleFirstName = (e) => {
    setFname(e.target.value);
  }

  const handleLastName = (e) => {
    setLname(e.target.value);
  }

  const handleCurrentPass = (e) => {
    setCurPassword(e.target.value);
  }

  const handleNewPass = (e) => {
    setNewPassword(e.target.value);
  }

  const handleConfirmPass = (e) => {
    setConPassword(e.target.value);
  }






  const apiCall = () => {

    setShowLoader(true)
    let payload = {

      display_first_name: fname.trim(),
      display_last_name: lname.trim(),
      old_password: curPassword,
      password: newPassword,
      password_confirmation: conPassword
    }

    const response = api.userAccountUpdate.userAccountUpdate(payload).then((response) => {
      console.log(response);
      if (response.data.status) {

        setShowLoader(false);
        toast.success(response.data.message);
        GlobalService.userData = response.data.data.user_data;
        localStorage.setItem('token', response.data.data.token);
        localService.setUserData(response.data.data.user_data);
        setConPassword("");
        setCurPassword("");
        setNewPassword("");
        // navigate('/dashboard')
      } else {
        setShowLoader(false);
        toast.error(response.data.message);
      }
    }).catch((err) => {
      console.log(err)
      setShowLoader(false);
      toast.error(err.message);
    });

  }

  const doSubmit = (e) => {
    e.preventDefault();
    let nameRegex = /^[a-zA-Z ]+$/;
    const email_rejex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const password_rejex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const inValid = /\s/;
    if (email != '' && !email_rejex.test(email)) {
      toast.error('Please enter a valid email address');
      return false;
    }
    else if (email_rejex.test(email)) {
      console.log('valid email');
    }

    if (fname != '' && !nameRegex.test(fname)) {
      toast.error('Please enter a valid Name');
      return false;
    }
    else if (nameRegex.test(fname)) {
      console.log('valid name');
    }


    if (lname != '' && !nameRegex.test(lname)) {
      toast.error('Please enter a valid Name');
      return false;
    }
    else if (nameRegex.test(lname)) {
      console.log('valid name');
    }


    if (curPassword != '') {
      if (newPassword != '' || conPassword != '') {

        if (newPassword == curPassword) {

          toast.error('Current Password and New Password cannot be same!');
        }
        else {
          if (newPassword == conPassword) {
            if (newPassword.length > 7) {
              if (password_rejex.test(newPassword) && !inValid.test(newPassword)) {
                apiCall();
              } else {

                toast.error('Invalid Password Format!The password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character, but cannot contain whitespace!');
              }
            } else {

              toast.error('The password must be at least 8 character!');
            }
          } else {

            toast.error('The passwords do not match!');
          }
        }
      } else {
        apiCall();
      }
    } else {

      toast.error('Please enter your current password!');
    }
  }

  return (

    <div className="container-fluid pl-0">
      <Row>
          <Col lg={2} className="px-0">
              <div className="sticky-head border-0 sideBarStick">
                <SideBar/>
              </div>
          </Col>

          <Col lg={10} className="pl-4">
            <Container>
              <Row className="my-4 pb-3 contentBoxing">
                <Col md={12} className="d-none d-md-block">
                  <h2>Account Settings</h2>
                </Col>
              </Row>
              <Row>
                <Col md={8} className="m-auto" >
                  <p className="form-head">Personal Details</p>
                  <Form className="mb-5">
                    <Form.Group>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="Email address"
                          value={email}
                          disabled={true} 
                        // onChange={(e) => {
                        //   handleEmail(e);
                        // }}
                        />
                        <label htmlFor="email">Email address</label>
                      </Form.Floating>
                    </Form.Group>
                    <Form.Group>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          name="fname"
                          maxLength={25}
                          type="text"
                          placeholder="First name"
                          value={fname}
                          onChange={(e) => {
                            handleFirstName(e);
                          }}
                        />
                        <label htmlFor="email">First name</label>
                      </Form.Floating>
                    </Form.Group>
                    <Form.Group>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          name="lname"
                          maxLength={25}
                          type="text"
                          placeholder="Last name"
                          value={lname}
                          onChange={(e) => {
                            handleLastName(e);
                          }}
                        />
                        <label htmlFor="email">Last name</label>
                      </Form.Floating>
                    </Form.Group>
                    <Form.Group>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          name="password"
                          type="password"
                          placeholder="Current password"
                          value={curPassword}
                          onChange={(e) => {
                            handleCurrentPass(e);
                          }}
                        />
                        <label htmlFor="email">Current password</label>
                      </Form.Floating>
                    </Form.Group>


                  </Form>
                  <p className="form-head">Change Password</p>

                  <Form className="mb-5">
                    <Form.Group>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          name="npass"
                          type="password"
                          placeholder="New password"
                          value={newPassword}
                          onChange={(e) => {
                            handleNewPass(e);
                          }}
                        />
                        <label htmlFor="npass">New password</label>
                      </Form.Floating>
                    </Form.Group>
                    <Form.Group>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          name="cpass"
                          type="password"
                          placeholder="Confirm password"
                          value={conPassword}
                          onChange={(e) => {
                            handleConfirmPass(e);
                          }}
                        />
                        <label htmlFor="cpass">Confirm password</label>
                      </Form.Floating>
                    </Form.Group>
                    <button
                      type="submit"
                      class="form-control mt-4 btn btn-primary py-2 mb-5 btn-theme"
                      onClick={doSubmit}
                    >Update
                      {showLoader ?
                              <FontAwesomeIcon icon={faSpinner} className="spinner" />
                              : null}
                              
                    </button>
                  </Form>



                </Col>
              </Row>
            </Container>
          </Col>
      </Row>
    </div>
  );
}

export default AccountSettings;
