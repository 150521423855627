// import React, {useState} from 'react';
// import axios from 'axios';
// // MUI Components
// import { Button, Card, Container, Row, Col, NavLink } from "react-bootstrap";
// // stripe
// import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
// // Util imports
// // Custom Components
// import CardInput from './CardInput';
//import { toast } from 'react-toastify';

// function CheckoutForm() {
//   const classes = 
//   ({
//     root: {
//       maxWidth: 500,
//       margin: '35vh auto',
//     },
//     content: {
//       display: 'flex',
//       flexDirection: 'column',
//       alignContent: 'flex-start',
//     },
//     div: {
//       display: 'flex',
//       flexDirection: 'row',
//       alignContent: 'flex-start',
//       justifyContent: 'space-between',
//     },
//     button: {
//       margin: '2em auto 1em',
//     },
//   });;
//   // State
//   const [email, setEmail] = useState('');

//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmitPay = async (event) => {
//     if (!stripe || !elements) {
//       // Stripe.js has not yet loaded.
//       // Make sure to disable form submission until Stripe.js has loaded.
//       return;
//     }

//     // const res = await axios.post('http://localhost:3000/pay', {email: email});

//     // const clientSecret = res.data['client_secret'];

//     // const result = await stripe.confirmCardPayment(clientSecret, {
//     //   payment_method: {
//     //     card: elements.getElement(CardElement),
//     //     billing_details: {
//     //       email: email,
//     //     },
//     //   },
//     // });


//     // const {error, paymentMethod} = await stripe.createPaymentMethod({
//     //     type: 'card',
//     //     card: elements.getElement(CardElement),
//     //   });


//       const card = elements.getElement(CardElement);
//       const result = await stripe.createToken(card);
//       if (result.error) {
//         console.log(result.error.message);
//       } else {
//         console.log('result.token',result.token);
//         console.log('result',result);
//       }

//     if (result.error) {
//       // Show error to your customer (e.g., insufficient funds)
//       console.log(result.error.message);
//     } else {
//       // The payment has been processed!
//       if (result.paymentIntent.status === 'succeeded') {
//         console.log('Money is in the bank!');
//         // Show a success message to your customer
//         // There's a risk of the customer closing the window before callback
//         // execution. Set up a webhook or plugin to listen for the
//         // payment_intent.succeeded event that handles any business critical
//         // post-payment actions.
//       }
//     }
//   };

//   const handleSubmitSub = async (event) => {
//     if (!stripe || !elements) {
//       // Stripe.js has not yet loaded.
//       // Make sure to disable form submission until Stripe.js has loaded.
//       return;
//     }

//     const result = await stripe.createPaymentMethod({
//       type: 'card',
//       card: elements.getElement(CardElement),
//       billing_details: {
//         email: email,
//       },
//     });

//     if (result.error) {
//       console.log(result.error.message);
//     } else {
//       const res = await axios.post('http://localhost:3000/sub', {'payment_method': result.paymentMethod.id, 'email': email});
//       // eslint-disable-next-line camelcase
//       const {client_secret, status} = res.data;

//       if (status === 'requires_action') {
//         stripe.confirmCardPayment(client_secret).then(function(result) {
//           if (result.error) {
//             console.log('There was an issue!');
//             console.log(result.error);
//             // Display error message in your UI.
//             // The card was declined (i.e. insufficient funds, card has expired, etc)
//           } else {
//             console.log('You got the money!');
//             // Show a success message to your customer
//           }
//         });
//       } else {
//         console.log('You got the money!');
//         // No additional information was needed
//         // Show a success message to your customer
//       }
//     }
//   };

//   return (
//     <Card className={classes.root}>
//       {/* <CardContent className={classes.content}> */}
//         {/* <TextField
//           label='Email'
//           id='outlined-email-input'
//           helperText={`Email you'll recive updates and receipts on`}
//           margin='normal'
//           variant='outlined'
//           type='email'
//           required
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           fullWidth
//         /> */}
//          <input
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               className="form-control"
//               placeholder="email"
//               aria-label="email"
//               aria-describedby="basic-addon1"
//             />
//         <CardInput />
//         <div className={classes.div}>
//           <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitPay}>
//             Pay
//           </Button>
         
//         </div>
//       {/* </CardContent> */}
//     </Card>
//   );
// }

// export default CheckoutForm;

import React, {useState,useEffect} from 'react';
import axios from 'axios';
// MUI Components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
// stripe
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
// Util imports
import {makeStyles} from '@material-ui/core/styles';
// Custom Components
import CardInput from './CardInput';
import { useNavigate, redirect, } from "react-router-dom";
import GlobalService from "../../services/globalService";

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: '35vh auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
  },
  button: {
    margin: '2em auto 1em',
  },
});

function HomePage({pay}) {
 
  const classes = useStyles();
  // State
  const [email, setEmail] = useState('');
  const [setUpIntentClientSecret, setSetUpIntentClientSecret] = useState("");

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const stripePrice = JSON.parse(localStorage.getItem('localStorage_StPrice'));
  const stripePriceId = JSON.parse(localStorage.getItem('localStorage_StPriceID'));


  useEffect(() => {
    testApi3();
  }, []);

  
  const testApi3 = () => {
    // let payload = {
    // 	priceid : "price_1MZsO3KfBR346qLzmji1c2TN"
    // }
    fetch('https://api.stripe.com/v1/setup_intents', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + "sk_test_51MTSk0KfBR346qLzQRXDqC57B36zGZfNsUwyI7WjUWeBuGpXnumLbc3a9xXOp0iO1L9GYo9t7tknGiVzGfaMrRFB00v8l6xUJf"
      },
      // body: JSON.stringify("price_1MZsO3KfBR346qLzmji1c2TN"),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data.client_secret);
        setSetUpIntentClientSecret(data.client_secret)
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }
  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    
    // const card = elements.getElement(CardElement);
    // const result = await stripe.createToken(card);
    // if (result.error) {
    //   console.log(result.error.message);
    // } else {
    //   console.log('result.token',result.token);
    //   console.log('result',result);
    // }
  // *************** used token just to show the payment success**********

    // const res = await axios.post('http://localhost:3000/pay', {email: email});

    // const clientSecret = res.data['client_secret'];

    // const result = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //     billing_details: {
    //       email: email,
    //     },
    //   },
    // });

    // ************ unable to understand why the localhost is used can discard this***********

    // const result = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: elements.getElement(CardElement),
    //   billing_details: {
    //     // Include any additional collected billing details.
    //     name: 'Jenny Rosen',
    //     email: email,
    //   },
    // });

// *********** above createPaymentMethod search from stripe docs and easy creating the payment_method without any setupinttent also this is applied on prowriter codes****************

    const result = stripe
    .confirmCardSetup(setUpIntentClientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Jenny Rosen',
          email: email,
        },
      },
    })
    .then(function(result) {
      // Handle result.error or result.setupIntent
      console.log('result-------',result)
    });

  //  ********** setUpIntentClientSecret also creating the payment_method though for this i hv used a useeffect to create the setupIntent*********

    // if (result.error) {
    //   // Show error to your customer (e.g., insufficient funds)
    //   console.log(result.error.message);
    //   toast.error("Please choose valid year which cannot be in the past!")
    // } else {
    //   // The payment has been processed!
    //   // if (result.paymentIntent.status === 'succeeded') {
    //     if (result.token.card.id) {
    //     console.log('Money is in the bank!');
    //     navigate("/subscribtion")
    //     // Show a success message to your customer
    //     // There's a risk of the customer closing the window before callback
    //     // execution. Set up a webhook or plugin to listen for the
    //     // payment_intent.succeeded event that handles any business critical
    //     // post-payment actions.
    //   }
    // }
  };


  return (
    <Card className={classes.root}>
      <span>
        {/* {console.log('GlobalService.stripeValue*100',GlobalService.stripeValue*100)} */}
      {/* <h3>{parseInt(GlobalService.stripeValue*100)}</h3> */}
      <h3>${stripePrice?stripePrice:0}</h3>
      <h5>{stripePriceId?stripePriceId:null}</h5>
      </span>
      
      <CardContent className={classes.content}>
        <TextField
          label='Email'
          id='outlined-email-input'
          helperText={`Email you'll recive updates and receipts on`}
          margin='normal'
          variant='outlined'
          type='email'
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <CardInput />
        <div className={classes.div}>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
            Pay
          </Button>
          {/* <Button variant="contained" color="primary" className={classes.button}>
            Subscription
          </Button> */}
        </div>
      </CardContent>
    </Card>
  );
}

export default HomePage;