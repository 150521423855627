import React, { lazy, useEffect, useState, Fragment } from "react";
import { Button, Col, Container, Row, Card, Alert } from "react-bootstrap";
import Filter from "../../images/filter.png";
import SitePrivacyCard from "./SitePrivacyCard";
import api from "../../apis"
import FilterModal from "../FilterModal";
import { faSpinner, faChevronDown, faChevronUp, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalService from "../../services/globalService";
import EmailVerify from "../../images/emailverify.png";
import BinIcon from "../../images/bin.png";
import NoImage from "../../images/no-image.png";
import Table from 'react-bootstrap/Table';
import LoaderImg from "../../images/download.gif";
import Modal from 'react-bootstrap/Modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useNavigate } from "react-router-dom";
// Sidebar include
import SideBar from "../../components/sidebar/SideBar";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';

function Index(props) {
  const [list, setList] = useState([]);
  const [privacyResult, setPrivacyResult] = useState([]);
  const [exposedPrivacyResult, setExposedPrivacyResult] = useState([]);
  const [probablePrivacyResult, setProbablePrivacyResult] = useState([]);
  const [appliedPrivacyResult, setAppliedPrivacyResult] = useState([]);
  const [clearPrivacyResult, setClearPrivacyResult] = useState([]);
  const [removedPrivacyResult, setRemovedPrivacyResult] = useState([]);
  const [noScrapSiteList, setNoScrapSiteList] = useState([]);
  const [actionRequiredList, setActionRequiredList] = useState([]);
  const [clearRemovePrivacyResult, setClearRemovePrivacyResult] = useState([]);
  const [showLoader, setShowLoader] = useState(false)
  const [modalShow, setModalShow] = React.useState(false);
  const [title, setTitle] = useState('All Reactions');
  const [sortValue, setSortValue] = useState('all');
  const [sortLabel, setSortLabel] = useState('All');
  const [showResults, setShowResults] = useState(false);
  const [privacyError, setPrivacyError] = useState("");
  const [exposedDataCount, setTotalExposedCount] = useState(0);
  const [totalSiteCount, setTotalSiteCount] = useState(0);
  const [totalSiteScannedCount, setTotalSiteScannedCount] = useState(0);
  const [remainingSiteScannedCount, setRemainingSiteScannedCount] = useState(0);
  const [checkStatus, setCheckStatus] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [isLoadStarted, setLoadStarted] = useState(false);
  const [load, setLoad] = useState(false);
  const [allResultData, setAllResultData] = useState({});
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showExposedBox, setShowExposedBox] = useState(true);
  const [currentCount, setCurrentCount] = useState(0);

  const navigate = useNavigate();

  let totalSites = 0;
  let totalScannedSites = 0;
  let remainingSiteCount = 0;
  let totalExposedSites = 0;
  let webhookDataCount = 0;

  var guest_user_data = JSON.parse(localStorage.getItem('guest_user_data'));


  const getTimeDifference = () => {
    var createdDateTimestamp = localStorage.getItem('current_timestamp');
    var currentTimestamp = new Date().valueOf(); //converting into timestamp
    var diff = currentTimestamp - createdDateTimestamp;
    var minutesDifference = Math.round((diff/1000)/60);
    return minutesDifference;
  }

   /*Following arrays are created for the table skeleton */
   const skeletonRowNumbers = ["1", "2", "3", "4" , "5", "6", "7", "8" , "9" , "10" ];
   const skeletonCellNumbers = ["1", "2", "3", "4" , "5" , "6", "7"];


  useEffect(() => {
    console.log('123');

    let subscriptionData = (GlobalService.subscriptionData);

    if(subscriptionData.length > 0) {
      if(subscriptionData[subscriptionData.length - 1].status.toLowerCase() === "active") {
        setShowExposedBox(false);
      }
    }

    localStorage.setItem('current_timestamp',new Date().valueOf());
    apiCall();

    callPrivacyApiFunction();
  }, []);



  const callPrivacyApiFunction = () => {
    const TIMING = 1000;
    
    /*check whether webhook exists in DB */
    let payload = {
      customer_email: GlobalService.localStorageData.email,
    }
    let count = 0;
    api.privacyApi.checkUserWebhookStatus(payload).then(async (response) => {
      if (response.data.status) {
       count = response.data.count;
      }
    }).then(function() {
      console.log('count = '+count);
      
      if(count > 0) {
        privacyApiCall();
      } else {
        let minutesDifference = getTimeDifference();
        let hourDifference = minutesDifference / 60;

        var interval = 0;
        let setIntervalFlag = false;
        privacyApiCall();
        
        if(!setIntervalFlag) {
          if ((hourDifference <= 5 && totalSiteScannedCount < totalSiteCount) ||(hourDifference <= 5 && totalSiteCount == 0)) {
            interval = setInterval(() => {
              let minDiff = getTimeDifference()
              console.log('logs in every 1 seconds...'+minDiff);
              privacyApiCall('retrieve_webhook');
              if (((totalScannedSites == totalSites) && (totalSites !== 0)) || ((totalScannedSites !== totalSites) && (totalSites !== 0) && (minDiff >= 2)) || (totalSites == 0 && minDiff >=2)) {
                clearInterval(interval);
                privacyApiCall(); /*This API is called to load the extra site details which are not there with the user's scan result*/
              } 
            }, TIMING);
          }
          setIntervalFlag = true;
        }
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      }
    });
  }



  const removeExposedInfo = (site_title = null, index =  null) => {

    const LoggedInuserData = GlobalService.localStorageData;

      if(index == null) {
        index = 0;
      }
      document.getElementById("exposed-btn-spinner-"+index).style.display = "inline-block";
      document.getElementById("exposed-btn-container-"+index).classList.add("disableClass");
      var user_data = JSON.parse(localStorage.getItem('localStorage_userData'));
      let flag = 0;

      /*DO NOT REMOVE THE FOLLOWING IF CONDITION. The following "if" condition is written to provide user  the offer of free data removals of 2 sites under the free plan  */
      //if(localStorage.getItem('removal_request_applied_for_the_site_name_details') !== null && localStorage.getItem('removal_request_applied_for_the_site_name_details') !== '') {
        //if((JSON.parse(localStorage.getItem('removal_request_applied_for_the_site_name_details'))).length == 2) {
          let payload = {
            user_id: user_data.id,
          }
          api.privacyApi.getSubscriptionData(payload).then(async (response) => {
            let subscriptionData = response.data.subscription_data;
            if(subscriptionData.length == 0){
              flag = 1;
            }
            if(subscriptionData.length > 0) {
              if(subscriptionData[subscriptionData.length - 1].status.toLowerCase() === "cancelled") {
                flag = 1;
              }
            }
            if(flag == 1) {
              setShowModal(true);
              document.getElementById("exposed-btn-spinner-"+index).style.display = "none";
              document.getElementById("exposed-btn-container-"+index).classList.remove("disableClass");
            } else {
              upDateSubscription(user_data.email, site_title, index);
            }
          }).catch((err) => {
            console.log(err)
          });
        // } else {
        //   upDateSubscription(user_data.email, site_title, index);
        // }
      // } else {
      //   upDateSubscription(user_data.email, site_title, index);
      // }
  }

  const upDateSubscription = (email, site_title,index) => {
    let updatePayload = {
      email: email, 
      site_title: site_title
    }
    api.privacyApi.updateSubscriptionData(updatePayload).then(async (response) => {
      console.log(response);
      if(response.data.status == true) {
        toast.success('Removal request applied successfullyYour request for data removal has been received. You can track the status using the tabs located at the top of privacy table.');
        localStorage.removeItem('removal_request_applied_for_the_site_name_details');
        localStorage.setItem('removal_request_applied_for_the_site_name_details',JSON.stringify(response.data.removal_request_applied_for_the_site_name_details))
        privacyApiCall();
      }
    }).then((f) => {
      const timer = setTimeout(() => {
        showDetails(index);
        document.getElementById("exposed-btn-spinner-"+index).style.display = "none";
        document.getElementById("exposed-btn-container-"+index).classList.remove("disableClass");
      }, 2500);
      return () => clearTimeout(timer);
      
    });
  }

  const closeModal = () => {
    setShowModal(false);
  }

  const redirectToPlanPage = () => {
    setShowModal(false);
    navigate('/plans-and-billing');
  }

  function checkIfImageExists(url, callback) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      img.onerror = () => {
        callback(false);
      };
    }
  }

  function checkUserWebhookStatus() {
    let payload = {
      customer_email: GlobalService.localStorageData.email,
    }
    let count = 0;

    api.privacyApi.checkUserWebhookStatus(payload).then(async (response) => {
      if (response.data.status) {
       count = response.data.count;
        //return response.data.count;
        // webhookDataCount = response.data.count;
        // setWebHookCount(response.data.count);
        // console.log(webhookDataCount);
      }
    }).then(function() {
      console.log('count = '+count);
      webhookDataCount = count
    });
    
  }



  const apiCall = () => {
    setShowLoader(true)
    const response = api.dashboard.get().then((response) => {
      console.log(response.data);
      if (response.data.status) {
        setShowLoader(false);
        setAllResultData(response.data)
        GlobalService.mentionResult = response.data.data.social_results;
        GlobalService.userDashboardAll = response.data.data.user;
        GlobalService.subscriptionData = response.data.data.subscription_data;
        // privacyApiCall();
        // console.log(response.data)
        if (GlobalService.subscriptionData.length == 0) {
          setShowActionSheet(true)
        } else {
          setShowActionSheet(false)
        }
      } else {
        setShowLoader(false);
        toast.error(response.data.message);
      }
    }).catch((err) => {
      console.log(err)
      setShowLoader(false);
      toast.error(err.message);
    });

  }

  const privacyApiCall = (requestType = null) => {
    let payload = {
      // user_id: GlobalService.userDashboardAll.id
      user_id: GlobalService.localStorageData.id,
      type: 'actual_user'
    }
    //setShowLoader(true);
    setCheckStatus(false);

    let api_url = '';
    if(requestType == null) {
      console.log('old api call');
      api_url = api.privacyApi.privacyApi(payload);
    } else {
      console.log('new api call');
      api_url = api.privacyApi.retrievePrivacyWebhookDataApi(payload);
    }

    const response = api_url.then(async (response) => {
      console.log(response);
      if (response.data.status) {
        GlobalService.privacyResult = response.data;

        totalSites = response.data.total_sites;
        totalScannedSites = response.data.total_site_scanned;
      

        /*********************** Validating the favicon and site-logo *****************************/
        if (response.data.results.length > 0) {
          //let resultData = response.data.results;
          // for (let i = 0; i < resultData.length; i++) {
          //   checkIfImageExists(resultData[i]['icon'], (exists) => {
          //     if (exists) {
          //       resultData[i]['site_icon'] = resultData[i]['icon'];
          //     } else {
          //       checkIfImageExists(resultData[i]['logo'], (existLogo) => {
          //         if (existLogo) {
          //           resultData[i]['site_icon'] = resultData[i]['logo'];
          //         } else {
          //           resultData[i]['site_icon'] = NoImage;
          //         }
          //       })
          //     }
          //   })
          // }
          setPrivacyResult(response.data.results);

          setExposedPrivacyResult(response.data.exposing_result);
          setAppliedPrivacyResult(response.data.applied_result);
          setClearPrivacyResult(response.data.clear_result);
          setRemovedPrivacyResult(response.data.removed_result);
          setClearRemovePrivacyResult(response.data.clear_removed_result);
          setNoScrapSiteList(response.data.no_scrap_sites);
          setActionRequiredList(response.data.action_required);
          setProbablePrivacyResult(response.data.probable_result);

          setShowResults(response.data.show_results);

          setTotalExposedCount(response.data.exposed_site_data_counter);
          setTotalSiteCount(response.data.total_sites);
          setTotalSiteScannedCount(response.data.total_site_scanned);
          setRemainingSiteScannedCount(response.data.remaining_site_scanned);

          setCurrentCount(response.data.exposing_result);

          await setShowLoader(false);

          setTimeout(async () => {
            // setShowLoader(false);
            await setCheckStatus(true);
            // imageURL();
          }, 4000);
        } else {
          setShowLoader(false);
        }
        /*********************** Validating the favicon and site-logo *****************************/
      } else {
        setShowLoader(false);
      }
    }).catch((err) => {
      console.log(err)
      setShowLoader(false);
      setPrivacyError(err.message)
      console.log('err.message-------------', err.message)
      // toast.error(err.message);
    });

  }

  const onChangeSortValue = async (value, label) => {
    //console.log('value and label ', value, label)
    // this.setState({ showFilterArea: false, title: label });
    if (label == 'All') {
      label = 'All Reactions'
    }

    setTitle(label);
    await setSortValue(value);
    await setSortLabel(label);

    let finalValue = (value == 'exposed') ? 'true' : 'false';

    if (GlobalService.privacyResult != '') {
      let dataObj = { ...GlobalService.privacyResult };
      let data = [...GlobalService.privacyResult.results];
      let newData = data.filter(function (item) {
        let name = item.exposed_status.toString();
        return name.indexOf(finalValue) > -1
      });

      //console.log('new data', newData);

      if (value != 'all' && newData.length > 0) {
        setPrivacyResult(newData)
      } else {
        if (value == 'all') {
          setPrivacyResult(dataObj.results)
        } else {
          setPrivacyResult([]);
        }
      }
    }
    setModalShow(false);
  }


  const showDetails = (index) => {

    let linkText = document.getElementById('show-hide-details-link-' + index).textContent;

    if(linkText.indexOf('Show') > -1) {
      document.getElementById('show-hide-details-link-' + index).textContent = 'Hide';
      document.getElementById('privacy-details-'+index).classList.remove("hideContainer");
      document.getElementById('show-details-link-' + index).classList.add("hideContainer");
      document.getElementById('hide-details-link-' + index).classList.remove("hideContainer");
    } else {
      document.getElementById('show-hide-details-link-' + index).textContent = 'Show';
      document.getElementById('privacy-details-'+index).classList.add("hideContainer");
      document.getElementById('show-details-link-' + index).classList.remove("hideContainer");
      document.getElementById('hide-details-link-' + index).classList.add("hideContainer");
    }
  }

  const getCount = (count) => {
    setCurrentCount(count);
  }

  // const imageURL = (imgurl) => {
  //   console.log('imgurlimgurl', imgurl)
  //   // document.getElementById("imgg").onload();
  //   document.getElementById("imgg").addEventListener("load", window.onload);// working
  //   document.getElementById("imgg").addEventListener("load", privacyApiCall);// working

  //   // return imgurl?.site_icon ? imgurl.site_icon : NoImage

  //   // window.onload=function(){
  //   //   // document.getElementById("imgg").click();
  //   //   document.getElementById("imgg").addEventListener("load",privacyApiCall());
  //   // };

  // }

  // window.onload = function pageLoad() {
  //   if (load) {
  //     window.location.reload(true);
  //     setLoad(false);
  //   }
  // };


  return (
    <>
      
      <div className={showLoader ? "container-fluid pl-0 with-skeleton" : ((currentCount == 0) ? "container-fluid pl-0 free-consultant-page-container zero-data-container" : "container-fluid pl-0 free-consultant-page-container")}>
        <Row>
          <Col lg={2} md={3} className="px-0">
            <div className="sticky-head border-0 sideBarStick">
              <SideBar/>
            </div>
          </Col>

          <Col lg={10} md={9} className="pl-4">
          {showLoader ?
            // <div className="loader">
            //   <FontAwesomeIcon icon={faSpinner} className="spinner"
            //   />
            //   <span>Loading...</span>
            // </div>
             <>
             <Row className="my-4 pb-3 sticky-head">
                <Col md={8} xs={6}>
                  <h2>Privacy</h2>
                </Col>
              </Row>
              <Row className="privacy-table-container">
                  <>
                    <Tabs>
                      <TabList>
                        <Tab style={{ width:200 }}><span className="tabName "><Skeleton /></span></Tab>
                      </TabList>
                      <TabPanel>
                        <Table className="all-privacy" striped responsive>
                          <thead>
                            <tr>
                              <th><Skeleton /></th>
                              <th><Skeleton /></th>
                              <th><Skeleton /></th>
                              <th className="relative-header"><Skeleton /></th>
                              <th><Skeleton /></th>
                              <th><Skeleton /></th>
                              <th><Skeleton /></th>
                            </tr>
                          </thead>
                          <tbody>
                            {skeletonRowNumbers.map((skel, indexNumber) => (
                                <tr className = {(indexNumber%2 == 0) ? '' : 'white-row'}>  
                                    {skeletonCellNumbers.map((s) => (
                                      <td>  <Skeleton />  </td>
                                    ))}
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </TabPanel>
                    </Tabs>
                  </>
                </Row>
             </>
            :
            <Container>

              <div className="container my-4">
              <Row className="my-4 pb-3 sticky-head">
                <Col md={8} xs={6}>
                  <h2>Privacy</h2>
                </Col>
              
                  <Col md={4} xs={6}>
                    {/* <button className="search-filter" onClick={() => setModalShow(true)}>
                      <p>{title != '' && title != undefined ? title : 'All Results'}</p>
                      <img src={Filter} alt="" />
                    </button>
                    <FilterModal
                      show={modalShow}
                      isExposed={true}
                      onHide={() => setModalShow(false)}
                      onChangeSortValue={onChangeSortValue}
                    /> */}
                  </Col>
                
                </Row>
                {
                  privacyError != "" ?
                    // <span className="no-result">No privacy results to display</span>
                    <div className="verify">
                    <Card className="verify-card">
                      <Card.Body>
                        <img src={EmailVerify} />
                        {allResultData && allResultData.data && allResultData.data.user && allResultData.data.user.email_notification == 0 ?
                        <div>
                        
                          <h6 style={{ margin: 0, top: "60%" }}>Your result is being processed, we will notify you when it is ready.</h6>
                        </div>
                        :
                        <div>
                          <h4>Email verification</h4> 
                        <h6 style={{ margin: 0, top: "60%" }}>Please verify your email address to enable all features.</h6>
                      </div>
                      }
                      </Card.Body>
                    </Card>
                  </div>
                    :
                    !showResults ?
                      // GlobalService.privacyResult.email_btn?
                      <div className="verify">
                        <Card className="verify-card privacy-verify-card">
                          <Card.Body>
                            {(GlobalService.privacyResult.email_btn || GlobalService.privacyResult.exposed_note) &&
                              <img src={EmailVerify} />
                            }
                            
                            <div>
                              {
                                GlobalService.privacyResult.email_btn ? <h4>Email verification</h4> : ''
                              }

                              <h6 style={{ margin: 0, top: "60%" }}>{GlobalService.privacyResult.exposed_note ? GlobalService.privacyResult.exposed_note : ''}</h6>
                            </div>

                            {(!GlobalService.privacyResult.email_btn && !GlobalService.privacyResult.exposed_note) &&
                                <Row className="privacy-table-container">
                                  <>
                                    <Tabs>
                                      <TabList>
                                        <Tab>
                                          <span className="tabName">EXPOSED INFO</span>
                                        </Tab>
                                        <Tab>
                                          <span className="tabName">PROBABLE</span>
                                        </Tab>
                                        {/* <Tab>
                                          <span className="tabName">ACTION REQUIRED</span>
                                        </Tab> */}
                                        <Tab>
                                          <span className="tabName">IN PROGRESS</span>
                                        </Tab>
                                        <Tab>
                                          <span className="tabName">COMPLETE</span>
                                        </Tab>
                                      </TabList>
                                      <TabPanel>
                                        <Table className="all-privacy" striped responsive>
                                          <thead>
                                            <tr>
                                              <th>SITE</th>
                                              <th>NAME</th>
                                              <th>LOCATION</th>
                                              <th className="relative-header">RELATIVES</th>
                                              <th>PHONE</th>
                                              <th>MORE INFO</th>
                                              <th>STATUS</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {skeletonRowNumbers.map((skel, indexNumber) => (
                                                <tr className = {(indexNumber%2 == 0) ? '' : 'white-row'}>  
                                                    {skeletonCellNumbers.map((s) => (
                                                      <td>  <Skeleton />  </td>
                                                    ))}
                                                </tr>
                                              ))}
                                          </tbody>
                                        </Table>
                                      </TabPanel>
                                    </Tabs>
                                  </>
                                </Row>  
                            }
                          </Card.Body>
                        </Card>
                      </div>
                      :
                      <>
                        <Row className="privacy-table-container contentBoxing">
                        {privacyResult.length > 0 ? (
                          <Fragment>
                            {exposedPrivacyResult.length > 0 && 
                              <h2 className="exposed-heading">Your Private Info: 
                                &nbsp;<span className="exposed-status-label">Very Exposed</span>
                              </h2>
                            }
                            <Tabs>
                              <TabList>
                                <Tab onClick={() => {getCount(exposedPrivacyResult.length)}}>
                                  <span className="tab-count exposed-tab-count">{exposedPrivacyResult.length}</span>
                                  <span className="tabName">EXPOSED INFO</span>
                                </Tab>
                                <Tab onClick={() => {getCount(probablePrivacyResult.length)}}>
                                  <span className="tab-count exposed-tab-count">{probablePrivacyResult.length}</span>
                                  <span className="tabName">PROBABLE</span>
                                </Tab>
                                {/* <Tab onClick={() => {getCount(actionRequiredList.length)}}>
                                  <span className="tab-count">{actionRequiredList.length}</span>  
                                  <span className="tabName">ACTION REQUIRED</span>
                                </Tab> */}
                                <Tab onClick={() => {getCount(appliedPrivacyResult.length)}}>
                                  <span className="tab-count">{appliedPrivacyResult.length}</span>
                                  <span className="tabName">IN PROGRESS</span>
                                </Tab>
                                <Tab onClick={() => {getCount(removedPrivacyResult.length)}}>
                                  <span className="tab-count">{removedPrivacyResult.length}</span>
                                  <span className="tabName">COMPLETE</span>
                                </Tab>
                              </TabList>

                              <TabPanel>
                                {exposedPrivacyResult.length > 0 ? 
                                  <div className="exposeContents">

                                    {showExposedBox && 
                                    <div className="deleteContent pb-5 d-flex flex-column align-items-center">
                                      <div className="binBox d-flex">
                                        <img src={BinIcon} width={70}/>
                                        <span>{exposedPrivacyResult.length}</span>
                                      </div>
                                      <div className="delContent">
                                        <p>Your private info is exposed <span>{exposedPrivacyResult.length}</span> times. Remove it in one click.</p>
                                        <p>The exposed information below puts you at <span>high risk</span> of identity theft, hacks, spam, robo dials and other privacy threats. We can remove your info from them and ensure it doesn't reappear. </p>
                                      </div>
                                      <div className="delContentBtn">
                                        <button onClick={() => redirectToPlanPage()}>Remove My Exposed info now</button>
                                      </div>
                                    </div>}
                                    <Table className="all-privacy" striped responsive>
                                      <thead>
                                        <tr>
                                          <th className="site-header">SITE</th>
                                          <th>NAME</th>
                                          <th>LOCATION</th>
                                          <th className="relative-header">RELATIVES</th>
                                          <th>PHONE</th>
                                          <th>MORE INFO</th>
                                          <th>STATUS</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      {exposedPrivacyResult.map((exposed, index) => {
                                          return(
                                            <>
                                              <tr
                                                key={exposed.key}
                                                className={(index%2 == 0) ? 'gray-row' : 'white-row'}
                                                onClick={() =>
                                                  showDetails(index)
                                                }
                                              >
                                                <td
                                                  verticalAlign="middle"
                                                  className=""
                                                >
                                                  <img
                                                    id="imgg"
                                                    className="logo-img"
                                                    src={
                                                      exposed.site_icon != '' ? exposed.site_icon : NoImage
                                                    }
                                                  />
                                                  <h4>{exposed.title}</h4>
                                                </td>
                                                <td verticalAlign="middle">
                                                  <div className="d-flex flex-column">
                                                    {" "}
                                                      <h5>{exposed.name}</h5>
                                                    {" "}
                                                    {(exposed.age !== '-') ? (<h6>Age: <span>{exposed.age}</span></h6>) : ''}
                                                  </div>

                                                </td>
                                                <td verticalAlign="middle" className="wrap-data">
                                                  <h5>{exposed.location}</h5>{" "}
                                                </td>
                                                <td verticalAlign="middle wrap-data">
                                                  {" "}
                                                  <h5>{exposed.all_relatives}</h5>{" "}
                                                </td>
                                                <td verticalAlign="middle">{exposed.phone_number}</td>
                                                <td verticalAlign="middle">
                                                    {exposed.exposing_status != 'clear' && exposed.exposing_status != 'removed' && exposed.exposing_status != '-' &&
                                                    <>
                                                      <span id={'show-hide-details-link-' + index} className="hideContainer">Show</span>
                                                      <a id={'show-details-link-' + index} href="javacript:void(0);">
                                                        Show{" "}
                                                        <FontAwesomeIcon
                                                          icon={faChevronDown}
                                                          style={{ color: "#0d6efd" }}
                                                        />
                                                      </a>
                                                      <a id={'hide-details-link-' + index} className="hideContainer" href="javacript:void(0);">
                                                        Hide{" "}
                                                        <FontAwesomeIcon
                                                          icon={faChevronUp}
                                                          style={{ color: "#0d6efd" }}
                                                        />
                                                      </a>
                                                    </>
                                                  }
                                                </td>
                                                <td>
                                                {exposed.exposing_status == 'exposed' && 
                                                  <span className="exposed-tag">
                                                    <i
                                                      class="fa fa-warning"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp; Exposed
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'clear' && 
                                                  <span className="not-exposed-tag">
                                                    <i
                                                      class="fa fa-check"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp; Clear
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'applied' && 
                                                  <span className="in-progress-tag">
                                                      <i
                                                        class="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; In Progress
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'removed' && 
                                                  <span className="remove-tag">
                                                      <i
                                                        class="fa fa-check"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; Removed
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'action_required' && 
                                                  <span className="action-required-tag">
                                                      <i
                                                        class="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; Action Required
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'probable' && 
                                                      <span className="probable-tag">
                                                          <i
                                                            class="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                          ></i>
                                                        &nbsp; Probable
                                                      </span>
                                                }
                                                </td>
                                              </tr>
                                              <tr id={'privacy-details-' + index} className="detailsTableCollapse hideContainer">
                                                <td colSpan={2}>
                                                  <div className="collapseBox">
                                                    <h2>This site also exposes your:</h2>
                                                    <ul>
                                                      <li>
                                                        <FontAwesomeIcon
                                                          icon={faTriangleExclamation}
                                                          style={{ color: "#ff0000" }}
                                                        />{" "}
                                                        Date of birth
                                                      </li>
                                                      <li>
                                                        <FontAwesomeIcon
                                                          icon={faTriangleExclamation}
                                                          style={{ color: "#ff0000" }}
                                                        />{" "}
                                                        Court, arrest, criminal & civil records
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </td>
                                                <td colSpan={2}>
                                                  <div className="collapseBox">
                                                    <h2>This info puts you at risk of:</h2>
                                                    <ul>
                                                      <li>Identity theft</li>
                                                      <li>Robocalls</li>
                                                      <li>Email spam</li>
                                                      <li>Hackers</li>
                                                      <li>Stalkers and creeps</li>
                                                      <li>Companies buying your data</li>
                                                    </ul>
                                                  </div>
                                                  <div id={'exposed-btn-container-' + index}>
                                                    {exposed.request_removal_applied == 'true' ? 
                                                      <a className="button-standard already-requested-button" href="javascript:void(0);">
                                                        Request Submitted
                                                      </a>
                                                    :
                                                    <a className="button-standard btn-theme" onClick={() => removeExposedInfo(exposed.title, index)}>
                                                        Remove My Exposed Info <FontAwesomeIcon icon={faSpinner} className="spinner exposed-btn-spinner" id={'exposed-btn-spinner-' + index}/>
                                                    </a>
                                                    }
                                                
                                                  </div>
                                                </td>
                                                <td colSpan={3}>
                                                  <div className="collapseBox">
                                                    <h2>To protect yourself:</h2>
                                                    <p>
                                                      Our software can automatically remove all of
                                                      your sensitive personal information from
                                                      this site (and all 79 major data brokers).
                                                      Click the button below to protect yourself.
                                                    </p>
                                                  </div>  
                                                </td>
                                              </tr>
                                            </>
                                            );
                                        })}
                                      </tbody>
                                  </Table>
                                  </div>
                                : <span className="noDataFoundText">No Data Found</span>}
                              </TabPanel>
                              

                              <TabPanel>
                                {probablePrivacyResult.length > 0 ? 
                                  <div className="exposeContents">

                                    <Table className="all-privacy" striped responsive>
                                      <thead>
                                        <tr>
                                          <th className="site-header">SITE</th>
                                          <th>NAME</th>
                                          <th>LOCATION</th>
                                          <th className="relative-header">RELATIVES</th>
                                          <th>PHONE</th>
                                          <th>MORE INFO</th>
                                          <th>STATUS</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      {probablePrivacyResult.map((exposed, index) => {
                                          return(
                                            <>
                                              <tr
                                                key={exposed.key}
                                                className={(index%2 == 0) ? 'gray-row' : 'white-row'}
                                                onClick={() =>
                                                  showDetails(index)
                                                }
                                              >
                                                <td
                                                  verticalAlign="middle"
                                                  className=""
                                                >
                                                  <img
                                                    id="imgg"
                                                    className="logo-img"
                                                    src={
                                                      exposed.site_icon != '' ? exposed.site_icon : NoImage
                                                    }
                                                  />
                                                  <h4>{exposed.title}</h4>
                                                </td>
                                                <td verticalAlign="middle">
                                                  <div className="d-flex flex-column">
                                                    {" "}
                                                      <h5>{exposed.name}</h5>
                                                    {" "}
                                                    {(exposed.age !== '-') ? (<h6>Age: <span>{exposed.age}</span></h6>) : ''}
                                                  </div>

                                                </td>
                                                <td verticalAlign="middle" className="wrap-data">
                                                  <h5>{exposed.location}</h5>{" "}
                                                </td>
                                                <td verticalAlign="middle wrap-data">
                                                  {" "}
                                                  <h5>{exposed.all_relatives}</h5>{" "}
                                                </td>
                                                <td verticalAlign="middle">{exposed.phone_number}</td>
                                                <td verticalAlign="middle">
                                                    {exposed.exposing_status != 'clear' && exposed.exposing_status != 'removed' && exposed.exposing_status != '-' &&
                                                    <>
                                                      <span id={'show-hide-details-link-' + index} className="hideContainer">Show</span>
                                                      <a id={'show-details-link-' + index} href="javacript:void(0);">
                                                        Show{" "}
                                                        <FontAwesomeIcon
                                                          icon={faChevronDown}
                                                          style={{ color: "#0d6efd" }}
                                                        />
                                                      </a>
                                                      <a id={'hide-details-link-' + index} className="hideContainer" href="javacript:void(0);">
                                                        Hide{" "}
                                                        <FontAwesomeIcon
                                                          icon={faChevronUp}
                                                          style={{ color: "#0d6efd" }}
                                                        />
                                                      </a>
                                                    </>
                                                  }
                                                </td>
                                                <td>
                                                {exposed.exposing_status == 'exposed' && 
                                                  <span className="exposed-tag">
                                                    <i
                                                      class="fa fa-warning"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp; Exposed
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'clear' && 
                                                  <span className="not-exposed-tag">
                                                    <i
                                                      class="fa fa-check"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp; Clear
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'applied' && 
                                                  <span className="in-progress-tag">
                                                      <i
                                                        class="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; In Progress
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'removed' && 
                                                  <span className="remove-tag">
                                                      <i
                                                        class="fa fa-check"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; Removed
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'action_required' && 
                                                  <span className="action-required-tag">
                                                      <i
                                                        class="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; Action Required
                                                  </span>
                                                }
                                                 {exposed.exposing_status == 'probable' && 
                                                      <span className="probable-tag">
                                                          <i
                                                            class="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                          ></i>
                                                        &nbsp; Probable
                                                      </span>
                                                }
                                                </td>
                                              </tr>
                                              <tr id={'privacy-details-' + index} className="detailsTableCollapse hideContainer">
                                                <td colSpan={2}>
                                                  <div className="collapseBox">
                                                    <h2>This site also exposes your:</h2>
                                                    <ul>
                                                      <li>
                                                        <FontAwesomeIcon
                                                          icon={faTriangleExclamation}
                                                          style={{ color: "#ff0000" }}
                                                        />{" "}
                                                        Date of birth
                                                      </li>
                                                      <li>
                                                        <FontAwesomeIcon
                                                          icon={faTriangleExclamation}
                                                          style={{ color: "#ff0000" }}
                                                        />{" "}
                                                        Court, arrest, criminal & civil records
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </td>
                                                <td colSpan={2}>
                                                  <div className="collapseBox">
                                                    <h2>This info puts you at risk of:</h2>
                                                    <ul>
                                                      <li>Identity theft</li>
                                                      <li>Robocalls</li>
                                                      <li>Email spam</li>
                                                      <li>Hackers</li>
                                                      <li>Stalkers and creeps</li>
                                                      <li>Companies buying your data</li>
                                                    </ul>
                                                  </div>
                                                  <div id={'exposed-btn-container-' + index}>
                                                    {exposed.request_removal_applied == 'true' ? 
                                                      <a className="button-standard already-requested-button" href="javascript:void(0);">
                                                        Request Submitted
                                                      </a>
                                                    :
                                                    <a className="button-standard btn-theme" onClick={() => removeExposedInfo(exposed.title, index)}>
                                                        Remove My Exposed Info <FontAwesomeIcon icon={faSpinner} className="spinner exposed-btn-spinner" id={'exposed-btn-spinner-' + index}/>
                                                    </a>
                                                    }
                                                
                                                  </div>
                                                </td>
                                                <td colSpan={3}>
                                                  <div className="collapseBox">
                                                    <h2>To protect yourself:</h2>
                                                    <p>
                                                      Our software can automatically remove all of
                                                      your sensitive personal information from
                                                      this site (and all 79 major data brokers).
                                                      Click the button below to protect yourself.
                                                    </p>
                                                  </div>  
                                                </td>
                                              </tr>
                                            </>
                                            );
                                        })}
                                      </tbody>
                                  </Table>
                                  </div>
                                : <span className="noDataFoundText">No Data Found</span>}
                              </TabPanel>

                              {/* <TabPanel>
                                {actionRequiredList.length > 0? 
                                  <Table className="all-privacy" striped responsive>
                                      <thead>
                                        <tr>
                                          <th className="site-header">SITE</th>
                                          <th>NAME</th>
                                          <th>LOCATION</th>
                                          <th className="relative-header">RELATIVES</th>
                                          <th>PHONE</th>
                                          <th>MORE INFO</th>
                                          <th>STATUS</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      {actionRequiredList.map((exposed, index) => {
                                          return(
                                            <>
                                              <tr
                                                key={exposed.key}
                                                className={(index%2 == 0) ? 'gray-row' : 'white-row'}
                                                onClick={() =>
                                                  showDetails(index)
                                                }
                                              >
                                                <td
                                                  verticalAlign="middle"
                                                  className=""
                                                >
                                                  <img
                                                    id="imgg"
                                                    className="logo-img"
                                                    src={
                                                      exposed.site_icon != '' ? exposed.site_icon : NoImage
                                                    }
                                                  />
                                                  <h4>{exposed.title}</h4>
                                                </td>
                                                <td verticalAlign="middle">
                                                  <div className="d-flex flex-column">
                                                    {" "}
                                                      <h5>{exposed.name}</h5>
                                                    {" "}
                                                    {(exposed.age !== '-') ? (<h6>Age: <span>{exposed.age}</span></h6>) : ''}
                                                  </div>

                                                </td>
                                                <td verticalAlign="middle" className="wrap-data">
                                                  <h5>{exposed.location}</h5>{" "}
                                                </td>
                                                <td verticalAlign="middle wrap-data">
                                                  {" "}
                                                  <h5>{exposed.all_relatives}</h5>{" "}
                                                </td>
                                                <td verticalAlign="middle">{exposed.phone_number}</td>
                                                <td verticalAlign="middle">
                                                    {exposed.exposing_status != 'clear' && exposed.exposing_status != 'removed' && exposed.exposing_status != '-' &&
                                                    <>
                                                      <span id={'show-hide-details-link-' + index} className="hideContainer">Show</span>
                                                      <a id={'show-details-link-' + index} href="javacript:void(0);">
                                                        Show{" "}
                                                        <FontAwesomeIcon
                                                          icon={faChevronDown}
                                                          style={{ color: "#0d6efd" }}
                                                        />
                                                      </a>
                                                      <a id={'hide-details-link-' + index} className="hideContainer" href="javacript:void(0);">
                                                        Hide{" "}
                                                        <FontAwesomeIcon
                                                          icon={faChevronUp}
                                                          style={{ color: "#0d6efd" }}
                                                        />
                                                      </a>
                                                    </>
                                                  }
                                                </td>
                                                <td>
                                                {exposed.exposing_status == 'exposed' && 
                                                  <span className="exposed-tag">
                                                    <i
                                                      class="fa fa-warning"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp; Exposed
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'clear' && 
                                                  <span className="not-exposed-tag">
                                                    <i
                                                      class="fa fa-check"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp; Clear
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'applied' && 
                                                  <span className="in-progress-tag">
                                                      <i
                                                        class="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; In Progress
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'removed' && 
                                                  <span className="remove-tag">
                                                      <i
                                                        class="fa fa-check"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; Removed
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'action_required' && 
                                                  <span className="action-required-tag">
                                                      <i
                                                        class="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; Action Required
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'probable' && 
                                                      <span className="probable-tag">
                                                          <i
                                                            class="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                          ></i>
                                                        &nbsp; Probable
                                                      </span>
                                                }
                                                </td>
                                              </tr>
                                              <tr id={'privacy-details-' + index} className="detailsTableCollapse hideContainer">
                                                <td colSpan={2}>
                                                  <div className="collapseBox">
                                                    <h2>This site also exposes your:</h2>
                                                    <ul>
                                                      <li>
                                                        <FontAwesomeIcon
                                                          icon={faTriangleExclamation}
                                                          style={{ color: "#ff0000" }}
                                                        />{" "}
                                                        Date of birth
                                                      </li>
                                                      <li>
                                                        <FontAwesomeIcon
                                                          icon={faTriangleExclamation}
                                                          style={{ color: "#ff0000" }}
                                                        />{" "}
                                                        Court, arrest, criminal & civil records
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </td>
                                                <td colSpan={2}>
                                                  <div className="collapseBox">
                                                    <h2>This info puts you at risk of:</h2>
                                                    <ul>
                                                      <li>Identity theft</li>
                                                      <li>Robocalls</li>
                                                      <li>Email spam</li>
                                                      <li>Hackers</li>
                                                      <li>Stalkers and creeps</li>
                                                      <li>Companies buying your data</li>
                                                    </ul>
                                                  </div>
                                                  <div id={'exposed-btn-container-' + index}>
                                                    {exposed.request_removal_applied == 'true' ? 
                                                      <a className="button-standard already-requested-button" href="javascript:void(0);">
                                                        Request Submitted
                                                      </a>
                                                    :
                                                    <a className="button-standard btn-theme" onClick={() => removeExposedInfo(exposed.title, index)}>
                                                        Remove My Exposed Info <FontAwesomeIcon icon={faSpinner} className="spinner exposed-btn-spinner" id={'exposed-btn-spinner-' + index}/>
                                                    </a>
                                                    }
                                                
                                                  </div>
                                                </td>
                                                <td colSpan={3}>
                                                  <div className="collapseBox">
                                                    <h2>To protect yourself:</h2>
                                                    <p>
                                                      Our software can automatically remove all of
                                                      your sensitive personal information from
                                                      this site (and all 79 major data brokers).
                                                      Click the button below to protect yourself.
                                                    </p>
                                                  </div>  
                                                </td>
                                              </tr>
                                            </>
                                            );
                                        })}
                                      </tbody>
                                  </Table>
                                : <span className="noDataFoundText">No Data Found</span>}
                              </TabPanel> */}

                              <TabPanel>
                                {appliedPrivacyResult.length > 0 ? 
                                  <Table className="all-privacy" striped responsive>
                                      <thead>
                                        <tr>
                                          <th className="site-header">SITE</th>
                                          <th>NAME</th>
                                          <th>LOCATION</th>
                                          <th className="relative-header">RELATIVES</th>
                                          <th>PHONE</th>
                                          <th>MORE INFO</th>
                                          <th>STATUS</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      {appliedPrivacyResult.map((exposed, index) => {
                                          return(
                                            <>
                                              <tr
                                                key={exposed.key}
                                                className={(index%2 == 0) ? 'gray-row' : 'white-row'}
                                                onClick={() =>
                                                  showDetails(index)
                                                }
                                              >
                                                <td
                                                  verticalAlign="middle"
                                                  className=""
                                                >
                                                  <img
                                                    id="imgg"
                                                    className="logo-img"
                                                    src={
                                                      exposed.site_icon != '' ? exposed.site_icon : NoImage
                                                    }
                                                  />
                                                  <h4>{exposed.title}</h4>
                                                </td>
                                                <td verticalAlign="middle">
                                                  <div className="d-flex flex-column">
                                                    {" "}
                                                      <h5>{exposed.name}</h5>
                                                    {" "}
                                                    {(exposed.age !== '-') ? (<h6>Age: <span>{exposed.age}</span></h6>) : ''}
                                                  </div>

                                                </td>
                                                <td verticalAlign="middle" className="wrap-data">
                                                  <h5>{exposed.location}</h5>{" "}
                                                </td>
                                                <td verticalAlign="middle wrap-data">
                                                  {" "}
                                                  <h5>{exposed.all_relatives}</h5>{" "}
                                                </td>
                                                <td verticalAlign="middle">{exposed.phone_number}</td>
                                                <td verticalAlign="middle">
                                                    {exposed.exposing_status != 'clear' && exposed.exposing_status != 'removed' && exposed.exposing_status != '-' &&
                                                    <>
                                                      <span id={'show-hide-details-link-' + index} className="hideContainer">Show</span>
                                                      <a id={'show-details-link-' + index} href="javacript:void(0);">
                                                        Show{" "}
                                                        <FontAwesomeIcon
                                                          icon={faChevronDown}
                                                          style={{ color: "#0d6efd" }}
                                                        />
                                                      </a>
                                                      <a id={'hide-details-link-' + index} className="hideContainer" href="javacript:void(0);">
                                                        Hide{" "}
                                                        <FontAwesomeIcon
                                                          icon={faChevronUp}
                                                          style={{ color: "#0d6efd" }}
                                                        />
                                                      </a>
                                                    </>
                                                  }
                                                </td>
                                                <td>
                                                {exposed.exposing_status == 'exposed' && 
                                                  <span className="exposed-tag">
                                                    <i
                                                      class="fa fa-warning"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp; Exposed
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'clear' && 
                                                  <span className="not-exposed-tag">
                                                    <i
                                                      class="fa fa-check"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp; Clear
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'applied' && 
                                                  <span className="in-progress-tag">
                                                      <i
                                                        class="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; In Progress
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'removed' && 
                                                  <span className="remove-tag">
                                                      <i
                                                        class="fa fa-check"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; Removed
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'action_required' && 
                                                  <span className="action-required-tag">
                                                      <i
                                                        class="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; Action Required
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'probable' && 
                                                      <span className="probable-tag">
                                                          <i
                                                            class="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                          ></i>
                                                        &nbsp; Probable
                                                      </span>
                                                }
                                                </td>
                                              </tr>
                                              <tr id={'privacy-details-' + index} className="detailsTableCollapse hideContainer">
                                                <td colSpan={2}>
                                                  <div className="collapseBox">
                                                    <h2>This site also exposes your:</h2>
                                                    <ul>
                                                      <li>
                                                        <FontAwesomeIcon
                                                          icon={faTriangleExclamation}
                                                          style={{ color: "#ff0000" }}
                                                        />{" "}
                                                        Date of birth
                                                      </li>
                                                      <li>
                                                        <FontAwesomeIcon
                                                          icon={faTriangleExclamation}
                                                          style={{ color: "#ff0000" }}
                                                        />{" "}
                                                        Court, arrest, criminal & civil records
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </td>
                                                <td colSpan={2}>
                                                  <div className="collapseBox">
                                                    <h2>This info puts you at risk of:</h2>
                                                    <ul>
                                                      <li>Identity theft</li>
                                                      <li>Robocalls</li>
                                                      <li>Email spam</li>
                                                      <li>Hackers</li>
                                                      <li>Stalkers and creeps</li>
                                                      <li>Companies buying your data</li>
                                                    </ul>
                                                  </div>
                                                  <div id={'exposed-btn-container-' + index}>
                                                    {exposed.request_removal_applied == 'true' ? 
                                                      <a className="button-standard already-requested-button" href="javascript:void(0);">
                                                        Request Submitted
                                                      </a>
                                                    :
                                                    <a className="button-standard btn-theme" onClick={() => removeExposedInfo(exposed.title, index)}>
                                                        Remove My Exposed Info <FontAwesomeIcon icon={faSpinner} className="spinner exposed-btn-spinner" id={'exposed-btn-spinner-' + index}/>
                                                    </a>
                                                    }
                                                
                                                  </div>
                                                </td>
                                                <td colSpan={3}>
                                                  <div className="collapseBox">
                                                    <h2>To protect yourself:</h2>
                                                    <p>
                                                      Our software can automatically remove all of
                                                      your sensitive personal information from
                                                      this site (and all 79 major data brokers).
                                                      Click the button below to protect yourself.
                                                    </p>
                                                  </div>  
                                                </td>
                                              </tr>
                                            </>
                                            );
                                        })}
                                      </tbody>
                                  </Table>
                                : <span className="noDataFoundText">No Data Found</span>}
                              </TabPanel>

                              <TabPanel>
                                {removedPrivacyResult.length > 0 ?
                                  <Table className="all-privacy" striped responsive>
                                      <thead>
                                        <tr>
                                          <th className="site-header">SITE</th>
                                          <th>NAME</th>
                                          <th>LOCATION</th>
                                          <th className="relative-header">RELATIVES</th>
                                          <th>PHONE</th>
                                          <th>MORE INFO</th>
                                          <th>STATUS</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      {removedPrivacyResult.map((exposed, index) => {
                                          return(
                                            <>
                                              <tr
                                                key={exposed.key}
                                                className={(index%2 == 0) ? 'gray-row' : 'white-row'}
                                                onClick={() =>
                                                  showDetails(index)
                                                }
                                              >
                                                <td
                                                  verticalAlign="middle"
                                                  className=""
                                                >
                                                  <img
                                                    id="imgg"
                                                    className="logo-img"
                                                    src={
                                                      exposed.site_icon != '' ? exposed.site_icon : NoImage
                                                    }
                                                  />
                                                  <h4>{exposed.title}</h4>
                                                </td>
                                                <td verticalAlign="middle">
                                                  <div className="d-flex flex-column">
                                                    {" "}
                                                      <h5>{exposed.name}</h5>
                                                    {" "}
                                                    {(exposed.age !== '-') ? (<h6>Age: <span>{exposed.age}</span></h6>) : ''}
                                                  </div>

                                                </td>
                                                <td verticalAlign="middle" className="wrap-data">
                                                  <h5>{exposed.location}</h5>{" "}
                                                </td>
                                                <td verticalAlign="middle wrap-data">
                                                  {" "}
                                                  <h5>{exposed.all_relatives}</h5>{" "}
                                                </td>
                                                <td verticalAlign="middle">{exposed.phone_number}</td>
                                                <td verticalAlign="middle">
                                                    {exposed.exposing_status != 'clear' && exposed.exposing_status != 'removed' && exposed.exposing_status != '-' &&
                                                    <>
                                                      <span id={'show-hide-details-link-' + index} className="hideContainer">Show</span>
                                                      <a id={'show-details-link-' + index} href="javacript:void(0);">
                                                        Show{" "}
                                                        <FontAwesomeIcon
                                                          icon={faChevronDown}
                                                          style={{ color: "#0d6efd" }}
                                                        />
                                                      </a>
                                                      <a id={'hide-details-link-' + index} className="hideContainer" href="javacript:void(0);">
                                                        Hide{" "}
                                                        <FontAwesomeIcon
                                                          icon={faChevronUp}
                                                          style={{ color: "#0d6efd" }}
                                                        />
                                                      </a>
                                                    </>
                                                  }
                                                </td>
                                                <td>
                                                {exposed.exposing_status == 'exposed' && 
                                                  <span className="exposed-tag">
                                                    <i
                                                      class="fa fa-warning"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp; Exposed
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'clear' && 
                                                  <span className="not-exposed-tag">
                                                    <i
                                                      class="fa fa-check"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp; Clear
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'applied' && 
                                                  <span className="in-progress-tag">
                                                      <i
                                                        class="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; In Progress
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'removed' && 
                                                  <span className="remove-tag">
                                                      <i
                                                        class="fa fa-check"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; Removed
                                                  </span>
                                                }
                                                {exposed.exposing_status == 'action_required' && 
                                                  <span className="action-required-tag">
                                                      <i
                                                        class="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    &nbsp; Action Required
                                                  </span>
                                                }
                                                 {exposed.exposing_status == 'probable' && 
                                                      <span className="probable-tag">
                                                          <i
                                                            class="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                          ></i>
                                                        &nbsp; Probable
                                                      </span>
                                                }
                                                </td>
                                              </tr>
                                              <tr id={'privacy-details-' + index} className="detailsTableCollapse hideContainer">
                                                <td colSpan={2}>
                                                  <div className="collapseBox">
                                                    <h2>This site also exposes your:</h2>
                                                    <ul>
                                                      <li>
                                                        <FontAwesomeIcon
                                                          icon={faTriangleExclamation}
                                                          style={{ color: "#ff0000" }}
                                                        />{" "}
                                                        Date of birth
                                                      </li>
                                                      <li>
                                                        <FontAwesomeIcon
                                                          icon={faTriangleExclamation}
                                                          style={{ color: "#ff0000" }}
                                                        />{" "}
                                                        Court, arrest, criminal & civil records
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </td>
                                                <td colSpan={2}>
                                                  <div className="collapseBox">
                                                    <h2>This info puts you at risk of:</h2>
                                                    <ul>
                                                      <li>Identity theft</li>
                                                      <li>Robocalls</li>
                                                      <li>Email spam</li>
                                                      <li>Hackers</li>
                                                      <li>Stalkers and creeps</li>
                                                      <li>Companies buying your data</li>
                                                    </ul>
                                                  </div>
                                                  <div id={'exposed-btn-container-' + index}>
                                                    {exposed.request_removal_applied == 'true' ? 
                                                      <a className="button-standard already-requested-button" href="javascript:void(0);">
                                                        Request Submitted
                                                      </a>
                                                    :
                                                    <a className="button-standard btn-theme" onClick={() => removeExposedInfo(exposed.title, index)}>
                                                        Remove My Exposed Info <FontAwesomeIcon icon={faSpinner} className="spinner exposed-btn-spinner" id={'exposed-btn-spinner-' + index}/>
                                                    </a>
                                                    }
                                                
                                                  </div>
                                                </td>
                                                <td colSpan={3}>
                                                  <div className="collapseBox">
                                                    <h2>To protect yourself:</h2>
                                                    <p>
                                                      Our software can automatically remove all of
                                                      your sensitive personal information from
                                                      this site (and all 79 major data brokers).
                                                      Click the button below to protect yourself.
                                                    </p>
                                                  </div>  
                                                </td>
                                              </tr>
                                            </>
                                            );
                                        })}
                                      </tbody>
                                  </Table>
                                : <span className="noDataFoundText">No Data Found</span>} 
                              </TabPanel>
                            </Tabs>
                            
                          </Fragment>
                        ) : ''}
                        </Row>



                      </>
                }
              </div>
              {/* <Modal
                show={showActionSheet}
                onHide={() => setShowActionSheet(false)}>
                <Modal.Header closeButton>
                  <Modal.Title >Upgrade to Remove Your Exposed Private Info</Modal.Title>
                </Modal.Header>
                <img src={EmailVerify} className="mt-4" style={{ width: '100px', margin: 'auto' }} />
                <Modal.Body className="py-4 text-center">{"View Plans and Pricing"}</Modal.Body>
              </Modal> */}
            </Container>}
          </Col>
        </Row>
      </div>  

      <Modal
        show={showModal}
        onHide={() => closeModal()}
        className="customModalSearch"
        >
        <Modal.Header closeButton>
          <Modal.Title >Upgrade to Remove Your Exposed Private Info</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <div className="listUpBody">
            {/* <Alert variant='danger'> 
              <i class="fa fa-warning"></i>&nbsp;&nbsp;
              You have exhausted your free quota for 'Privacy Data Removal'. Please subscribe to request more. 
            </Alert> */}
            <p className="sub-header">Benifits of upgrading:</p>
            <ul>
              <li>Automatically remove your private info from 75+ websites in one click</li>
              <li>Keep your sensitive info (email, phone, address, age, etc.) private</li>
              <li>Protect yourself from spam, identity theft, hacks and robo calls</li>
              <li>Ongoing monitoring and removal any time your info reappears</li>
            </ul>
          </div>
          {/* <NavLink to="/plans-and-billing"> */}
            <button type="button" className="successMessageButton viewPlanPricingBtn" onClick={() => redirectToPlanPage()}>View Plans & Pricing</button>
          {/* </NavLink> */}
          <p className="modal-footer-text">No risk, no commitment. Cancel anytime.</p>
        </Modal.Body>
      </Modal>
    </>

  );
}

export default Index;

