import React, { useEffect, useState, useRef, useContext } from "react";
import { Container, Form, Button, Row, Col, Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { faArrowRight, faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validator from "validator";
import "./StepForm.scss";
// import ReactFlagsSelect from "../../assets/react-flags-select";
// import GlobalService from '../../services/globalService';
import AppContext from "../../context/AppContext"
import api from "../../apis";
import { toast } from 'react-toastify';

// Sections
import Header from "./header/Header";
import StepTwoForm from "./StepTwoForm";
import Footer from "./footer/Footer";


function CurrentLocation() {

    // const [selectedCountry, setSelectedCountry] = useState("US");
    // const [stateData, setStateData] = useState([]);
    // const [stateName, setStateName] = useState('Select State');
    // const [stateNameId, setStateNameId] = useState('');
    const [showLoader, setShowLoader] = useState(false)
    // const [cityData, setCityData] = useState([]);
    // const [cityName, setCityName] = useState("Select City");
    // const [cityNameId, setCityNameId] = useState("");
    const [currLocation, setCurrLocation] = useState({});

    // after form submit validating the form data using validator

    const appCtx = useContext(AppContext);
    const navigate = useNavigate();
    
    useEffect(() => {
      if(localStorage.getItem('customer_current_saved_email') !== null) {
        navigate("/scanning-result");
      }
    }, []);

   
    const goToPreviousStep = () => {
        navigate('/scanning-location');
    }

    const onSaveCurrentLocation = (e) => {
        e.preventDefault();
        var guest_user_data = JSON.parse(localStorage.getItem('guest_user_data'));

        setShowLoader(true);

        let payload = {
            email: guest_user_data.email,
            country_code: localStorage.getItem('user_current_location_country'),
            city_name: localStorage.getItem('user_current_location_city'),
            state_name: localStorage.getItem('user_current_location_state')
        }

        console.log('payload fron location', payload)
        const response = api.saveUserLocation.saveUserLocation(payload).then((response) => {
            console.log('res......', response);

            if (response.data.status) {
                setShowLoader(false);
                navigate('/scanning-result');

            } else {
                setShowLoader(false);
                if (response.data.message != undefined && response.data.message != '') {
                  toast.error(response.data.message);
                } else {
                    console.log('object');
                }
            }
        }).catch((err) => {
            setShowLoader(false);
            console.log(err.message);
            console.log(err);
        });

    }

    return (
      <>
        <Header />
        {/* <StepTwoForm /> */}

        <div className="stepTowForm pb-5">
          <section className="interStepsHeading">
            <Container>
              <Row>
                <Col lg={12} className="text-center">
                  <h1>Your Free Privacy Scan Is One Step Away!</h1>
                  <h2>
                    Do you live in {localStorage.getItem('user_current_location_city')},{" "}
                    {localStorage.getItem('user_current_location_state_code')}?
                  </h2>
                  <p style={{ color: "grey", fontSize: "20px" }}>
                    This will help narrow down your results from billion of
                    records
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <Form className="searchForm">
            {/*  */}
            <Container>
              <Row className="justify-content-center">
                <Col lg={4} md={6} xs={6}>
                  <Button
                    variant="primary"
                    disabled={showLoader}
                    className="mt-4 w-100 grayBtn rounded-0 border-0"
                    onClick={goToPreviousStep}
                  >
                    No
                  </Button>
                </Col>
                <Col lg={4} md={6} xs={6}>
                  <Button
                    className="form-control mt-4 rounded-0 border-0 btn-theme"
                    type="submit"
                    onClick={onSaveCurrentLocation}
                    disabled={showLoader}
                  >
                    Yes
                    {showLoader ? (
                      <FontAwesomeIcon icon={faSpinner} className="spinner" />
                    ) : null}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
        <Footer />
      </>
    );
}

export default CurrentLocation;
