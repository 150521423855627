import React, { useEffect } from 'react'
import { NavLink, useNavigate } from "react-router-dom";

function Auth(props) {
  const navigate = useNavigate();

  const { Component } = props;
  useEffect(() => {
    let checkValue = localStorage.getItem('token');
    if (!checkValue) {
      navigate('/');
    } 
  }, [])
  return (
    <div>
      <Component />
    </div>
  )
}

export default Auth
